<script>
    import { Icon } from '@iconify/vue';
    import Button from './Button.vue';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n'
    export default {
        name: "AdminsTable",
        components: {
            Icon,
            Button,
        },
        setup() {
            const { t } = useI18n() // use as global scope
            return {
                t
            }
        },
        data() {
            return {
                searchadmin: {
                    filter: ""
                },
                filteredAdmins: [],
                store: useStore(),
                headers: [
                    { title: this.$t('nome_admin'), value: 'name', sortable: true },
                    { title: this.$t('cognome_admin'), value: 'surname', sortable: true },
                    { title: this.$t('email_admin'), value: 'userEmail', sortable: true},
                    { title: '', value: 'userId', sortable: false },
                ],
                selectedSchool: null,
            }
        },
        props: {
            admins: {
                type: Array,
            },
            schools: {
                type: Array,
            },
            loading: {
                type: Boolean,
            }
        },
        mounted() {
            this.filteredAdmins = [...this.admins];
            this.searchChange();
            this.selectedSchool = null
        },
        // reazione ai cambiamenti dei props
        watch: {
            admins: function(newVal, oldVal) {
                this.searchChange()
            },
            selectedSchool: function(newValue) {
                this.updateSelectedSchool(newValue);
            },
        },
        methods: {
            searchChange() {
                const searchTerm = this.searchadmin.filter.trim().toLowerCase();
                console.log(searchTerm, "searchTerm");
                if (searchTerm) {
                    this.filteredAdmins = this.admins.filter((admin) => {
                        const fullName = `${admin.name} ${admin.surname}`.toLowerCase();
                        return (
                            fullName.includes(searchTerm) ||
                            admin.userEmail.toLowerCase().includes(searchTerm)
                        );
                    });
                } else {
                    this.filteredAdmins = [...this.admins];
                }
            },
            formatDate(dateTime) {
                return moment(dateTime).format('D/M/YYYY');
            },
            updateSelectedSchool(newValue) {
              console.log("scuola cambiata");
              const selectedSchoolObj = this.schools.find(school => school.name === newValue);

              if (selectedSchoolObj) {
                // Update the Vuex store with selectedSchool's name and ID
                this.store.state.global.idSelectedSchool = selectedSchoolObj.id
                this.store.state.global.selectedSchool = newValue
                this.$emit('update-school', selectedSchoolObj.id);
                console.log(this.store.state.global.idSelectedSchool);
              }
            },
        }
    }
</script>

<template>
    <div class="p-table-container">
        <div class="p-top-container">
            <Button v-if="this.store.state.global.selectedSchool" :aria-label="$t('agg_admin')" buttoncolor="black" :buttontext="$t('agg_admin')"  @btn-click="this.store.dispatch('toggleAddAdminForm')" />
            <input
                class="search"
                type="search"
                @input="searchChange"
                name="searchadmin"
                id="searchadmin"
                :placeholder="$t('cerca_admin')"
                v-model.trim="searchadmin.filter"
                :aria-label="$t('cerca_admin')"
                role="search"
                style="height: 50px;"
                v-if="this.store.state.global.selectedSchool"
            />
            <v-select
                :label="$t('seleziona_scuola')"
                :items="this.schools.map(school => school.name)"
                style="width: 200px; height: 55px;"
                variant="outlined"
                v-model="selectedSchool"
                @change="updateSelectedSchool"
                @input="updateSelectedSchool"
            ></v-select>
        </div>
       <div v-if="this.store.state.global.selectedSchool">
            <v-skeleton-loader 
                    boilerplate
                    :loading="this.loading"
                    type="table-thead, table-tbody, table-tfoot"
                    width="735"
                >
                <v-data-table :items="filteredAdmins" :headers="headers" style="outline: 1px solid rgba(0,0,0,0.2); border-radius: 10px; max-height: 500px;">
                    <template v-slot:item.userId="{ item }">
                        <div class="btn-group">
                        
                            <div v-if="item.userId === 1" style="width: 65px; height: 40px; border: 1px solid black; border-radius: 5px; display: flex; justify-content: center;align-items: center;">
                                <Icon color="black" icon="typcn:delete-outline" width="30" height="30" />
                                <v-tooltip activator="parent" location="bottom">
                                    {{ $t('no_rimuovi_primo_admin') }}
                                </v-tooltip>
                            </div>
                            
                            <Button v-else role="button" :aria-label="$t('elimina_admin')" buttoncolor="#fe2315" @btn-click="$emit('delete-admin', item.userId)">
                                <template #icon>
                                    <Icon color="black" icon="typcn:delete-outline" width="30" height="30" />
                                </template>
                                <template #tooltip>
                                    <v-tooltip activator="parent" location="bottom">
                                        {{ $t('elimina') }}
                                    </v-tooltip>
                                </template>
                            </Button>
                        </div>
                    </template>
                </v-data-table>
            </v-skeleton-loader>
        </div>
        <div v-else>{{ $t('seleziona_scuola') }}</div>
    </div>
</template>

<style scoped>
    .p-table-container{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
    }

    .search {
        width: 200px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 10px;
        padding-left: 10px;
        outline: solid 1px rgba(0,0,0,0.3);
        border-radius: 5px
    }
    .btn-group{
        margin-left:20px;
        width: fit-content;
        /* outline: black 1px solid; */
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 10px;
    }

    .p-top-container{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 20px;
    }
</style>
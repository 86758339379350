<script>
    import TeacherAssignmentsTable from '../components/TeacherAssignmentsTable.vue';
    import { useStore } from 'vuex';
    const axios = require('axios');
    export default {
        name: "TeacherAssignmentsView",
        components: {
            TeacherAssignmentsTable,
        },
        data() {
            return {
                loading: true,
                assignments: [],
                store: useStore(),
                schools: []
            }
        },
        async created() {
            const me = await this.fetchTeacherId();
            console.log(me);
            this.store.state.global.idSelectedSchool = null;
            this.store.state.global.selectedSchool = null;
            this.schools = me.professorInfo.schools
            if(this.store.state.global.idSelectedSchool) {
                this.updateAssignments()
            }
        },
        methods: {
            async fetchaAssignmentsProfessor() {

                const teacher = await this.fetchTeacherId();

                const apiUrl = `${this.$apiBaseUrl}/docenza/professore/${teacher.professorInfo.userId}/${this.store.state.global.idSelectedSchool}`;
                const bearerToken = this.$keycloak.token;

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    const response = await axios.get(apiUrl, config);
                    console.log('Response:', response.data);
                    const reversed = response.data.reverse();
                    return reversed;
                } catch (error) {
                    console.error('Error:', error.message);
                }
            },
            async updateAssignments() {
                this.assignments = await this.fetchaAssignmentsProfessor();
                setInterval(() => {
                    this.loading = false
                }, 300);
            },
            async fetchTeacherId() {
                const apiUrl = `${this.$apiBaseUrl}/users/me`;
                const bearerToken = this.$keycloak.token;

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    const response = await axios.get(apiUrl, config);
                    console.log('Response:', response.data);
                    return response.data;
                } catch (error) {
                    console.error('Error:', error.message);
                }
            },
        },
    }
</script>

<template>
    <div style="width: fit-content; margin-top: 47px; margin-bottom: 50px; min-height: 400px;">
        <div>
            <TeacherAssignmentsTable :loading="loading" :schools="schools" :assignments="assignments" @update-school="updateAssignments"/>
        </div>    
    </div>
</template>

<style scoped>
</style>
<script>
    import AdminsTable from '../components/AdminsTable.vue';
    import AddAdminForm from '../components/AddAdminForm.vue';
    const axios = require('axios');
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex';
    export default {
        name: "AdminsView",
        components: {
          AdminsTable,
            AddAdminForm,
        },
        data() {
            return {
                loading: true,
                admins: [],
                store: useStore(),
                schools: [],
            }
        },
        setup() {
            const { t } = useI18n() // use as global scope
            return {
                t
            }
        },
        async created() {
            try {
                const me = await this.fetchMe();
                console.log(me);
                this.store.state.global.idSelectedSchool = null;
                this.store.state.global.selectedSchool = null;
                if(me.adminInfo){
                    this.schools = me.superAdminInfo.schools
                    if(this.store.state.global.idSelectedSchool) {
                        this.updateAdmins()
                    }
                }
            } catch (error) {
                // Handle the error if needed
                console.error('Error in created hook:', error.message);
            }
        },
        methods: {
            // fetch dei dati nel db degli admins
            async fetchAdmins() {
                const apiUrl = `${this.$apiBaseUrl}/school/${this.store.state.global.idSelectedSchool}/admins`;
                const bearerToken = this.$keycloak.token;

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    const response = await axios.get(apiUrl, config);
                    console.log('Response:', response.data);
                    const reversed = response.data.reverse();
                    return reversed;
                } catch (error) {
                    console.error('Error:', error.message);
                }
            },
            async updateAdmins() {
                this.admins = await this.fetchAdmins();
                setInterval(() => {
                    this.loading = false
                }, 300);
            },
            async fetchMe() {
                console.log("entered fetchMe");
                const apiUrl = `${this.$apiBaseUrl}/users/me`;
                const bearerToken = this.$keycloak.token;

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    const response = await axios.get(apiUrl, config);
                    console.log('Response:', response.data);
                    return response.data;
                } catch (error) {
                    console.error('Error:', error.message);
                }
            },
            // funzione di fetch per prendere gli admin dal db per id
            async fetchAdmin(id) {
                const apiUrl = `${this.$apiBaseUrl}/admin/${id}`;
                const bearerToken = this.$keycloak.token;

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    const response = await axios.get(apiUrl, config);
                    console.log('Response:', response.data);
                    return response.data;
                } catch (error) {
                    console.error('Error:', error.message);
                }
            },
            
            async createAdmin(admin) {
                // Verifica se esiste già un admin con la stessa userEmail
                const adminExists = this.admins.some(existingAdmin => existingAdmin.userEmail === admin.userEmail);
                
                if (adminExists) {
                    this.store.dispatch('showAlert' , [this.$t('errore'), this.$t('errore_admin_esistente'), 5000, "#c42116", "bxs:error"])
                    return; // Non creare un nuovo admin se ne esiste già uno con la stessa userEmail #c42116
                }

                const apiUrl = `${this.$apiBaseUrl}/admin`;
                const bearerToken = this.$keycloak.token;

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    const response = await axios.post(apiUrl, admin, config);
                    console.log('Admin created:', response.data);
                    this.admins = await this.fetchAdmins();
                    this.store.dispatch('showAlert' , [this.$t('successo'), `${this.$t('successo_creazione_admin')}`, 5000, "#04c717", "ep:success-filled"])
                    this.store.dispatch('toggleAddAdminForm')
                } catch (error) {
                    this.store.dispatch('showAlert' , [this.$t('errore'), `${this.$t('errore_creazione_admin')} ${error.message}`, 5000, "#c42116", "bxs:error"])
                }
            },
            async deleteAdmin(id) {

                if(confirm(this.$t('conferma_eliminazione_admin'))){
                    const apiUrl = `${this.$apiBaseUrl}/admin/${id}/${this.store.state.global.idSelectedSchool}`;
                    const bearerToken = this.$keycloak.token;

                    const config = {
                        headers: {
                            'Authorization': `Bearer ${bearerToken}`,
                        },
                    };

                    try {
                        // Effettua la richiesta DELETE
                        const response = await axios.delete(apiUrl, config);
                        console.log('Admin deleted:', response.data);
                        this.store.dispatch('showAlert' , [this.$t('successo'), `${this.$t('successo_eliminazione_admin')}`, 5000, "#04c717", "ep:success-filled"])
                        this.admins = await this.fetchAdmins();
                    } catch (error) {
                        console.log(error);
                        this.store.dispatch('showAlert' , [this.$t('errore'), `${this.$t('errore_eliminazione_admin')} ${error.response.data.message}`, 5000, "#c42116", "bxs:error"])
                    }
                }
            },
        },
    }
</script>

<template>
    <div style="margin-top: 47px; margin-bottom: 50px; min-height: 400px;">
        <div v-show="this.store.state.admins.ariaShowAdmin">
            <AdminsTable :schools="schools" :loading="loading" :admins="admins" @delete-admin="deleteAdmin" @update-school="updateAdmins"/>
            
        </div>
    </div>
    <div v-show="this.store.state.admins.showAddAdminForm" class="form-container">
        <AddAdminForm @create-admin="createAdmin"/>
    </div>
</template>


<style scoped>
    .form-container{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 30px;
        align-items: center;
        position: absolute;
        top: 0;
        background-color: rgba(0,0,0,0.6);
        z-index: 100;
        backdrop-filter: blur(2px);
    }
</style>
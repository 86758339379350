import { createApp } from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import { loadFonts } from "./plugins/webfontloader";
import Keycloak from "keycloak-js";
import VueJwtDecode from "vue-jwt-decode";
import { createI18n } from "vue-i18n";

loadFonts();

const languages = ["it", "en"];

if (
  localStorage.getItem("lang") === null ||
  !languages.includes(localStorage.getItem("lang"))
) {
  localStorage.setItem("lang", "it");
}

const i18n = createI18n({
  locale: localStorage.getItem("lang"), // default locale
  allowComposition: true, // you need to specify that!
  messages: {
    it: {
      benvenuto: "Benvenuto",
      professori: "DOCENTI",
      admin: "AMMINISTRATORI",
      scuole: "SCUOLE",
      mie_docenze: "MIE DOCENZE",
      presenze: "PRESENZE",
      corsi: "CORSI",
      docenze: "DOCENZE",
      agg_professore: "AGGIUNGI DOCENTE",
      agg_scuola: "AGGIUNGI SCUOLA",
      agg_admin: "AGGIUNGI AMMINISTRATORE",
      agg_corso: "AGGIUNGI CORSO",
      agg_docenza: "AGGIUNGI DOCENZA",
      data_inizio: "Data Inizio",
      data_fine: "Data Fine",
      nome_professore: "Nome Docente",
      nome_scuola: "Nome Scuola",
      nome_corsista: "Nome Corsista",
      email_corsista: "Email Corsista",
      orario_presenza: "Orario Presenza al Corso",
      orario_qrcode: "Orario Attivazione QR-CODE",
      nome_admin: "Nome Amministratore",
      cognome_professore: "Cognome Docente",
      cognome_admin: "Cognome Amministratore",
      email_professore: "Email Docente",
      email_admin: "Email Amministratore",
      nome_corso: "Nome Corso",
      seleziona_professore: "Seleziona Docente",
      seleziona_corso: "Seleziona Corso",
      seleziona_scuola: "Seleziona Scuola",
      no_professori: "NESSUN DOCENTE TROVATO",
      no_admins: "NESSUN AMMINISTRATORE TROVATO",
      no_corsi: "NESSUN CORSO TROVATO",
      no_docenze: "NESSUNA DOCENZA TROVATA",
      no_presenze: "NESSUNA PRESENZA TROVATA",
      cerca_corso: "CERCA CORSO",
      cerca_professore: "CERCA DOCENTE",
      cerca_admin: "CERCA AMMINISTRATORE",
      cerca_scuola: "CERCA SCUOLA",
      cerca_presenza: "CERCA PRESENZA",
      seleziona_lingua: "Seleziona lingua",
      tabella_professori: "Tabella docenti",
      tabella_scuole: "Tabella scuole",
      tabella_admin: "Tabella amministratori",
      tabella_corsi: "Tabella corsi",
      tabella_docenze: "Tabella docenze",
      tabella_presenze: "Tabella presenze",
      form_modifica_professori: "Modulo di modifica docente",
      form_modifica_corsi: "Modulo di modifica corso",
      form_modifica_scuola: "Modulo di modifica scuola",
      form_aggiunta_professori: "Modulo di aggiunta docente",
      form_aggiunta_scuola: "Modulo di aggiunta scuola",
      form_aggiunta_admin: "Modulo di aggiunta amministratore",
      form_aggiunta_corsi: "Modulo di aggiunta corso",
      form_aggiunta_docenze: "Modulo di aggiunta docenza",
      invio: "INVIO",
      cancella: "CANCELLA",
      chiudi: "CHIUDI",
      elimina: "ELIMINA",
      modifica: "MODIFICA",
      colonna: "COLONNA",
      riga: "RIGA",
      errore: "ERRORE",
      inizia_docenza: "AVVIA DOCENZA",
      errore_professore_esistente:
        "C'è già un professore con lo stesso indirizzo e-mail",
      errore_scuola_esistente: "C'è già una scuola con lo stesso nome",
      errore_admin_esistente:
        "C'è già un'amministratore con lo stesso indirizzo e-mail",
      errore_creazione_professore: "Errore nella creazione del docente:",
      errore_creazione_scuola: "Errore nella creazione della scuola:",
      successo_creazione_professore: "Docente creato correttamente",
      successo_creazione_scuola: "Scuola creata correttamente",
      errore_creazione_admin: "Errore nella creazione dell'amministratore:",
      successo_creazione_admin: "Amministratore creato correttamente",
      errore_modifica_professore: "Errore nella modifica del docente:",
      successo_modifica_professore: "Docente modificato correttamente",
      successo_modifica_scuola: "Scuola modificato correttamente",
      errore_docenze_professore:
        "Il professore ha delle docenze e non può essere cancellato",
      errore_eliminazione_professore: "Errore nell'eliminazione del docente:",
      successo_eliminazione_professore: "Docente eliminato con successo",
      errore_eliminazione_admin:
        "Errore nell'eliminazione dell'amministratore:",
      successo_eliminazione_admin: "Amministratore eliminato con successo",
      errore_corso_esistente: "C'è già un corso con lo stesso nome",
      errore_creazione_corso: "Errore nella creazione del corso:",
      successo_creazione_corso: "Corso creato con successo",
      errore_modifica_corso: "Errore nella modifica del corso:",
      errore_modifica_scuola: "Errore nella modifica della scuola:",
      successo_modifica_corso: "Corso modificato con successo",
      errore_docenze_corso:
        "Il corso ha delle docenze e non può essere cancellato",
      errore_eliminazione_corso: "Errore nell'eliminazione del corso:",
      successo_eliminazione_corso: "Corso eliminato con successo",
      errore_docenza_esistente:
        "C'è già una docenza con lo stesso docente e lo stesso corso",
      errore_eliminazione_docenza: "Errore nell'eliminazione della docenza:",
      successo_eliminazione_docenza: "Docenzz eliminata con successo",
      errore_creazione_docenza: "Errore nella creazione della docenza:",
      successo_creazione_docenza: "Docenza creata con successo",
      successo_avviamento_docenza: "Docenza creata con successo",
      conferma_eliminazione_corso:
        "Sei sicuro di voler cancellare questo corso?",
      conferma_eliminazione_professore:
        "Sei sicuro di voler cancellare questo docente?",
      conferma_eliminazione_admin:
        "Sei sicuro di voler cancellare questo amministratore?",
      conferma_eliminazione_docenza:
        "Sei sicuro di voler cancellare questa docenza?",
      link_pagina_home: "Link alla pagina home",
      link_pagina_professori: "Link alla pagina docenti",
      link_pagina_corsi: "Link alla pagina corsi",
      link_pagina_docenze: "Link alla pagina docenze",
      link_pagina_presenze: "Link alla pagina presenze",
      link_pagina_scuole: "Link alla pagina scuole",
      elimina_professore: "Elimina docente",
      elimina_admin: "Elimina amministratore",
      elimina_corso: "Elimina corso",
      elimina_docenza: "Elimina docenza",
      modifica_professore: "Modifica docente",
      modifica_scuola: "Modifica scuola",
      modifica_corso: "Modifica corso",
      azioni_form: "Azioni del form",
      reset_filtro_docenze: "Togli filtro docenze",
      successo: "SUCCESSO",
      forbidden_page: "NON SEI AUTORIZZATO AD ACCEDERE A QUESTA PAGINA",
      forbidden_button: "TORNA ALLA HOME",
      carousel_titolo: "A chi è rivolto?",
      enti_formativi_titolo: "Enti formativi",
      enti_formativi_testo:
        "che desiderano e/o necessitano di verificare che il corsista sia effettivamente presente al corso on-line e che possono utilizzare HIA a tale scopo, indipendentemente dalla piattaforma di e-learning o teleconferenza utilizzata;",
      corsisti_titolo: "Corsisti",
      corsisti_testo:
        "che seguono un corso in FAD la cui presenza effettiva è verificata da HIA, che sottopone lo studente a delle challenge più o meno interattive ed esplicite allo scopo di verificarne la presenza e il grado di attenzione.o che seguono un corso in FAD la cui presenza effettiva è verificata da HIA, che sottopone lo studente a delle challenge più o meno interattive ed esplicite allo scopo di verificarne la presenza e il grado di attenzione.",
      docenti_titolo: "Docenti",
      docenti_testo:
        "che erogano corsi in FAD che possono utilizzare HIA per eseguire una verifica di presenza esplicita, appelli automatizzati e avere indici di attenzione alla lezione in tempo reale.",
      piattaforme_e_learning_titolo: "Piattaforme di e-learning",
      piattaforme_e_learning_testo:
        "che hanno la necessità di integrare le funzionalità offerte da HIA senza doverle implementare ex-novo.",
      header_titolo: "Verifica che gli studenti stiano ascoltando",
      header_testo:
        '"Here I Am" propone un sistema in grado di verificare che uno studente che partecipa a una sessione di formazione online sia effettivamente presente.',
      inizia: "INIZIA",
      affidibilita_titolo: "Affidibilità",
      affidibilita_testo:
        "L'affidabilità è il nostro valore cardine. Siamo impegnati a fornire un servizio costantemente affidabile e di alta qualità ai nostri clienti. Ci sforziamo di mantenere le nostre promesse e di superare le aspettative, garantendo che ogni interazione con noi sia affidabile e soddisfacente.",
      sicurezza_titolo: "Sicurezza",
      sicurezza_testo:
        "La sicurezza è la nostra massima priorità. Mettiamo al primo posto la protezione dei vostri dati e la vostra privacy. Abbiamo implementato rigorose misure di sicurezza per garantire che le vostre informazioni siano al sicuro da accessi non autorizzati o utilizzi impropri.",
      facilita_titolo: "Facilità",
      facilita_testo:
        "La facilità è il nostro obiettivo principale. Siamo dedicati a fornire un'esperienza semplice e intuitiva ai nostri clienti. Abbiamo progettato i nostri servizi per essere accessibili e facili da utilizzare, eliminando ogni ostacolo o complicazione.",
      login_step:
        "Lo studente deve effettuare il login con le credenziali aziendali",
      captcha_step: "Lo studente deve compilare un captcha di verifica",
      email_step: "Lo studente riceverà una email con un codice",
      step_obbligatorio: "Questo step è obbligatorio",
      step_selection_title: "Step di verifica presenza",
      step_info_title: "Guida alle Selezioni degli step di verifica",
      step_info_close: "CHIUDI INFO",
      step_info_1:
        "Benvenuto nella nostra guida rapida alle selezioni dei checkbox. In questa sezione, ti spiegheremo come funziona la selezione dei checkbox e le interazioni tra di essi.",
      step_info_2: "Come Funziona:",
      step_info_3: "Ogni checkbox rappresenta un passo o un'opzione.",
      step_info_4:
        "Alcuni passi richiedono l'esecuzione di altri passi prima di diventare selezionabili.",
      step_info_5:
        "Ad esempio, se selezioni 'login', il passo successivo 'captcha' diventerà selezionabile.",
      step_info_6:
        "Se deselezioni 'login', il passo 'captcha' verrà deselezionato automaticamente.",
      step_info_7:
        "Questo è dovuto al fatto che 'captcha' ha come requisito 'login'.",
      step_info_8: "Esempio Pratico:",
      step_info_9:
        "Seleziona 'login' per attivare il passo successivo 'captcha'.",
      step_info_10:
        "Se deselezioni 'login', 'captcha' verrà automaticamente deselezionato.",
      step_info_11:
        "Questo approccio semplifica il processo, assicurandoti di seguire l'ordine corretto delle azioni.",
      step_info_12: "Grazie per la tua attenzione e buona navigazione!",
      gestione_utenze: "GESTIONE UTENZE",
      gestione_corsi: "GESTIONE CORSI",
      tutte_docenze: "tutte le docenze",
      visualizzando: "Visualizzando",
      docenze_di_docente: "docenze di",
      docenze_di_corso: "docenze del corso",
      vedi_docenze: "VEDI DOCENZE",
      selezione_data_corso: "Seleziona data termine corso",
      selezione_data: "SELEZIONA DATA",
      cancella_data: "CANCELLA DATA",
      contattaci: "contattaci a ",
      titolo_contatto: "VUOI PROVARE LA NOSTRA APP?",
      testo_contatto:
        "per una demo e per ottenere un account di valutazione non esitare a contattarci ad ",
      titolo_featurette_1: "Rilevamento della presenza tramite sfide",
      testo_featurette_1:
        "HIA rileva la presenza dell’utente durante il corso mediante delle “sfide” che sono sottoposte all’utente tramite il canale utilizzato per la FAD o in maniera ad esso correlata. Tali sfide sono delle azioni che il corsista deve eseguire per poter dimostrare la sua presenza. Il punto chiave è che HIA è in grado di associare una sfida con un corso e con lo studente che dovrebbe essere presente a quel corso. Una sfida “superata” dimostra con un certo grado di confidenza che il corsista è presente al corso.",
      titolo_featurette_2: "Indagini",
      testo_featurette_2:
        "HIA può analizzare i dati raccolti dalle sfide per eseguire delle “indagini” e identificare situazioni dubbie di presenza. A puro titolo di esempio, supponiamo che un utente A sia sempre riuscito a superare le sfide proposte da HIA, ma sempre e solo dopo che c’è riuscito l’utente B. Questo potrebbe indicare che il corsista B stia cercando di impersonare l’utente A. Tale fase di indagine deve portare a degli indicatori che permettano di pianificare meglio le sfide a cui sottoporre i corsisti. Ad esempio, nel caso sopra descritto, si potrebbe pensare che alla prossima lezione, al corsista A sia sottoposta una sfida magari più invasiva ma con una confidenza maggiore. Oppure che il corsista A sia sfidato prima del corsista B.",
      titolo_alert_risoluzione: "ATTENZIONE",
      testo_alert_risoluzione:
        "la risoluzione dello schermo attuale non è consigliata per l'utilizzo del nostro sito, le consigliamo di utilizzare una risoluzione maggiore",
      no_prove_richieste: "NESSUNA PROVA RICHIESTA",
      no_prove: "NO PROVE",
      nessuna_prova: "nessuna prova",
      errore_i: "errore/i",
      orario_presenza: "Orario presenza",
      prove_richieste: "Prove richieste",
      prove: "Prove",
      rimuovi_filtri: "RIMUOVI FILTRI",
      no_rimuovi_primo_admin: "Non è possibile eliminare il primo admin",
      sei_admin_docente: "Sei un amministratore o un docente?",
      seleziona_steps: "SELEZIONA STEP DI VERIFICA",
      verificato: "SEI STATO VERIFICATO",
      attendi: "ATTENDI UN ATTIMO",
      controlla_mail: "CONTROLLA LA TUA CASELLA POSTALE",
    },
    en: {
      benvenuto: "Welcome",
      professori: "TEACHERS",
      admin: "ADMIN",
      scuole: "SCHOOLS",
      mie_docenze: "MY LECTURES",
      presenze: "PRESENCES",
      corsi: "COURSES",
      docenze: "LECTURES",
      agg_professore: "ADD TEACHER",
      agg_scuola: "ADD SCHOOL",
      agg_admin: "ADD ADMIN",
      agg_corso: "ADD COURSE",
      agg_docenza: "ADD LECTURE",
      data_inizio: "Start Date",
      data_fine: "Finish Date",
      cerca_corso: "SEARCH COURSE",
      cerca_professore: "SEARCH TEACHER",
      cerca_admin: "SEARCH ADMIN",
      cerca_scuola: "SEARCH SCHOOL",
      cerca_presenza: "SEARCH PRESENCE",
      nome_professore: "Teacher First Name",
      nome_scuola: "School Name",
      nome_admin: "Admin First Name",
      cognome_professore: "Teacher Last Name",
      cognome_admin: "Admin Last Name",
      email_professore: "Teacher Email",
      nome_corsista: "Student Name",
      email_corsista: "Student Email",
      orario_presenza: "Course attendance time",
      orario_qrcode: "QR-CODE activation time",
      email_admin: "Admin Email",
      nome_corso: "Course Name",
      seleziona_professore: "Select Teacher",
      seleziona_corso: "Select Course",
      seleziona_scuola: "Select School",
      no_professori: "NO TEACHERS FOUND",
      no_padmins: "NO ADMINS FOUND",
      no_corsi: "NO COURSES FOUND",
      no_docenze: "NO LECTURES FOUND",
      no_presenze: "NO PRESENCES FOUND",
      seleziona_lingua: "Select language",
      tabella_professori: "Teachers table",
      tabella_scuole: "Schools table",
      tabella_admin: "Admins table",
      tabella_corsi: "Courses table",
      tabella_docenze: "Lectures language",
      tabella_presenze: "Presences language",
      form_modifica_professori: "Teacher edit form",
      form_modifica_corsi: "Course edit form",
      form_modifica_scuola: "School edit form",
      form_aggiunta_professori: "Teacher addition form",
      form_aggiunta_scuola: "School addition form",
      form_aggiunta_admin: "Admin addition form",
      form_aggiunta_corsi: "Course addition form",
      form_aggiunta_docenze: "Lecture addition form",
      invio: "SUBMIT",
      cancella: "CLEAR",
      chiudi: "CLOSE",
      elimina: "DELETE",
      modifica: "EDIT",
      colonna: "COLUMN",
      riga: "ROW",
      errore: "ERROR",
      inizia_docenza: "START LECTURE",
      errore_professore_esistente:
        "There is already a teacher with the same email",
      errore_scuola_esistente: "There is already a school with the same name",
      errore_admin_esistente: "There is already an admin with the same email",
      errore_creazione_professore: "Error creating teacher:",
      errore_creazione_scuola: "Error creating school:",
      successo_creazione_professore: "Successfully created teacher",
      successo_creazione_scuola: "Successfully created school",
      errore_creazione_admin: "Error creating admin:",
      successo_creazione_admin: "Successfully created admin",
      errore_modifica_professore: "Error editing teacher:",
      successo_modificato_professore: "Successfully edited teacher",
      errore_docenze_professore: "Professor has lectures and cannot be deleted",
      errore_eliminazione_professore: "Error deleting teacher:",
      successo_eliminazione_professore: "Successfully deleted teacher",
      errore_eliminazione_admin: "Error deleting admin:",
      successo_eliminazione_admin: "Successfully deleted admin",
      errore_corso_esistente: "There is already a course with the same name",
      errore_creazione_corso: "Error creating course:",
      successo_creazione_corso: "Successfully created course",
      errore_modifica_corso: "Error editing course:",
      errore_modifica_scuola: "Error editing school:",
      successo_modifica_corso: "Course successfully edited",
      successo_modifica_professore: "Teacher successfully edited",
      successo_modifica_scuola: "School successfully edited",
      errore_docenze_corso: "Course has lectures and cannot be deleted",
      errore_eliminazione_corso: "Error deleting course:",
      successo_eliminazione_corso: "Successfully deleted course",
      errore_docenza_esistente:
        "There is already a lecture with this course and this teacher",
      errore_eliminazione_docenza: "Error deleting lecture:",
      successo_eliminazione_docenza: "Successfully deleted lecture",
      errore_creazione_docenza: "Error creating lecture:",
      successo_creazione_docenza: "Successfully created lecture",
      successo_avviamento_docenza: "Lecture started successfully",
      conferma_eliminazione_corso:
        "Are you sure you want to delete this course?",
      conferma_eliminazione_professore:
        "Are you sure you want to delete this teacher?",
      conferma_eliminazione_admin:
        "Are you sure you want to delete this admin?",
      conferma_eliminazione_docenza:
        "Are you sure you want to delete this lecture?",
      link_pagina_home: "Link to home page",
      link_pagina_professori: "Link to teachers page",
      link_pagina_corsi: "Link to courses page",
      link_pagina_docenze: "Link to lectures page",
      link_pagina_presenze: "Link to presences page",
      link_pagina_scuole: "Link to schools page",
      elimina_professore: "Delete teacher",
      elimina_admin: "Delete admin",
      elimina_corso: "Delete course",
      elimina_docenza: "Delete lecture",
      modifica_professore: "Edit teacher",
      modifica_scuola: "Edit school",
      modifica_corso: "Edit course",
      azioni_form: "Form actions",
      reset_filtro_docenze: "Reset lectures filter",
      successo: "SUCCESS",
      forbidden_page: "YOU ARE NOT ALLOWED TO ACCESS THAT PAGE",
      forbidden_button: "GO BACK HOME",
      carousel_titolo: "Who is it aimed at?",
      enti_formativi_titolo: "Training organisations",
      enti_formativi_testo:
        "who wish and/or need to verify that the trainee is actually present at the online course and who can use HIA for this purpose, regardless of the e-learning or teleconferencing platform used;",
      corsisti_titolo: "Trainees",
      corsisti_testo:
        "who follow an ODL course whose actual attendance is verified by HIA, which subjects the student to more or less interactive and explicit challenges in order to check their attendance and attention span.",
      docenti_titolo: "Teachers",
      docenti_testo:
        "that deliver ODL courses that can use HIA to perform explicit attendance verification, automated appeals and have real-time lecture attention rates.",
      piattaforme_e_learning_titolo: "E-learning platforms",
      piattaforme_e_learning_testo:
        "who need to integrate the functionalities offered by HIA without having to implement them from scratch.",
      header_titolo: "Check that the students are listening",
      header_testo:
        '"Here I Am" proposes a system that can verify that a student participating in an online training session is actually present.',
      inizia: "LETS START",
      affidibilita_titolo: "Dependability",
      affidibilita_testo:
        "Dependability is our core value. We are committed to providing a consistently reliable and high-quality service to our customers. We strive to deliver on our promises and exceed expectations, ensuring that every interaction with us is reliable and satisfying.",
      sicurezza_titolo: "Security",
      sicurezza_testo:
        "Security is our top priority. We put the protection of your data and your privacy first. We have implemented strict security measures to ensure that your information is safe from unauthorised access or misuse.",
      facilita_titolo: "Ease",
      facilita_testo:
        "Ease is our main goal. We are dedicated to providing a simple and intuitive experience for our customers. We have designed our services to be accessible and easy to use, removing any obstacles or complications.",
      login_step: "The student must log in with company credentials",
      captcha_step: "The student has to fill in a verification captcha",
      email_step: "The student will recive an email with a code",
      step_obbligatorio: "This step is mandatory",
      step_selection_title: "Presence verification step",
      step_info_title: "Guide to the selection of the verification steps",
      step_info_close: "CLOSE INFO",
      step_info_1:
        "Welcome to our quick guide to checkbox selections. In this section, we will explain how the selection of checkboxes works and the interactions between them.",
      step_info_2: "How does it work:",
      step_info_3: "Each checkbox represents a step or an option.",
      step_info_4:
        "Some steps require other steps to be performed before they become selectable.",
      step_info_5:
        "For example, if you select 'login', the next step 'captcha' will become selectable.",
      step_info_6:
        "If you uncheck 'login', the 'captcha' step will be unchecked automatically.",
      step_info_7:
        "This is due to the fact that 'captcha' has 'login' as a requirement.",
      step_info_8: "Practical Example:",
      step_info_9: "Select 'login' to activate the next 'captcha' step.",
      step_info_10:
        "If you uncheck 'login', 'captcha' will automatically be unchecked.",
      step_info_11:
        "This approach simplifies the process, ensuring that you follow the correct order of actions.",
      step_info_12: "Thank you for your attention and happy browsing!",
      gestione_utenze: "USERS MANAGEMENT",
      gestione_corsi: "COURSES MANAGEMENT",
      tutte_docenze: "all lectures",
      visualizzando: "Visualizing",
      docenze_di_corso: "lectures of the course",
      docenze_di_docente: "lectures of teacher",
      vedi_docenze: "VIEW LECTURES",
      selezione_data_corso: "Select course end date",
      selezione_data: "SELECT DATE",
      cancella_data: "DELETE DATE",
      contattaci: "contact us at ",
      titolo_contatto: "WOULD YOU LIKE TO TRY OUR APP?",
      testo_contatto:
        "for a demo and to get an evaluation account, feel free to contact us at ",
      titolo_featurette_1: "Presence detection via challenges",
      testo_featurette_1:
        'HIA detects user attendance during the course by means of "challenges" that are presented to the user through the channel used for the FAD or in a manner related to it. These challenges are actions that the trainee must perform in order to demonstrate attendance. The key point is that HIA is able to associate a challenge with a course and with the student who should be present for that course. A "passed" challenge demonstrates with some degree of confidence that the student is present for the course.',
      titolo_featurette_2: "Investigations",
      testo_featurette_2:
        'HIA can analyze the data collected from the challenges to perform "investigations" and identify questionable attendance situations. Purely as an example, suppose that a user A always succeeded in passing the challenges proposed by HIA, but always only after user B succeeded. This might indicate that Student B is trying to impersonate User A. This phase of investigation should lead to indicators to better plan the challenges to which the trainees will be subjected. For example, in the case described above, it might be thought that at the next lesson, Privateer A is given a challenge that is perhaps more invasive but with greater confidence. Or that Privateer A be challenged before Privateer B.',
      titolo_alert_risoluzione: "WARNING",
      testo_alert_risoluzione:
        "your current screen resolution is not recommended for use on our site, we recommend that you use a higher resolution",
      no_prove_richieste: "NO PROOF REQUIRED",
      no_prove: "NO PROOFS",
      nessuna_prova: "no proof",
      errore_i: "error(s)",
      prove: "Proofs",
      prove_richieste: "Proofs required",
      orario_presenza: "Hours of attendance",
      rimuovi_filtri: "REMOVE FILTERS",
      no_rimuovi_primo_admin: "It is not possible to delete the first admin",
      sei_admin_docente: "Are you an administrator or a teacher?",
      seleziona_steps: "SELECT VERIFICATION STEPS",
      verificato: "YOU HAVE BEEN VERIFIED",
      attendi: "WAIT A MOMENT",
      controlla_mail: "CHECK YOUR MAILBOX",
    },
  },
});

function loadVueJs(keycloak, auth, configJson) {
  const app = createApp(App).use(router).use(store).use(vuetify).use(i18n);
  app.config.globalProperties.$keycloak = keycloak;
  app.config.globalProperties.$apiBaseUrl = configJson["api"]["baseUrl"];
  app.mount("#app");

  if (auth) {
    console.log("Authenticated", auth);
    localStorage.setItem("vue-token", keycloak.token);
    localStorage.setItem("vue-refresh-token", keycloak.refreshToken);

    const token_decoded = VueJwtDecode.decode(keycloak.token);
    console.log(token_decoded);

    store.dispatch("store_lastname", token_decoded.family_name);
    store.dispatch("store_firstname", token_decoded.given_name);
    store.dispatch("store_email", token_decoded.email);
    store.dispatch("store_roles", token_decoded.realm_access.roles);
    store.dispatch("changeAuthorized", true);

    console.log("firstname: " + store.state.global.userData.firstname);
    console.log("lastname: " + store.state.global.userData.lastname);
    console.log("email: " + store.state.global.userData.email);
    console.log("roles: " + store.state.global.userData.roles);

    setInterval(() => {
      keycloak
        .updateToken(70)
        .then((refreshed) => {
          if (refreshed) {
            console.debug("Token refreshed" + refreshed);
          } else {
            console.warn(
              "Token not refreshed, valid for " +
                Math.round(
                  keycloak.tokenParsed.exp +
                    keycloak.timeSkew -
                    new Date().getTime() / 1000
                ) +
                " seconds"
            );
          }
        })
        .catch(() => {
          console.error("Failed to refresh token");
        });
    }, 60000);
  } else {
    store.dispatch("changeAuthorized", false);
  }
}

function loadKeycloak(configJson) {
  console.log(configJson, "configJson");
  let initOptions = {
    ...configJson["sso"],
    onLoad: "check-sso",
  };

  console.log("KC config", initOptions);

  let keycloak = new Keycloak(initOptions);

  keycloak
    .init({ onLoad: initOptions.onLoad })
    .then((auth) => loadVueJs(keycloak, auth, configJson))
    .catch((e) => {
      console.error("Authenticated Failed", e);
    });
}

fetch("/config/config.json")
  .then((response) => response.json())
  .then((configJson) => loadKeycloak(configJson));

<script>
    import { Icon } from '@iconify/vue';
    import Button from './Button.vue';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n'
    
    const moment = require('moment');
    export default {
        name: "CoursesTable",
        components: {
            Icon,
            Button,
        },
        setup() {
            const { t } = useI18n() // use as global scope
            return {
                t
            }
        },
        data() {
            return {
                filteredCourses: [],
                searchcourse: {
                    filter: ""
                },
                store: useStore(),
                headers: [
                    { title: this.$t('nome_corso'), value: 'nome', sortable: true },
                    { title: this.$t('data_inizio'), value: 'dataInizio', sortable: true },
                    { title: this.$t('data_fine'), value: 'dataFine', sortable: true },
                    { title: '', value: 'id', sortable: false },
                ],
                selectedSchool: null,
            }
        },
        props: {
            courses: {
                type: Array,
            },
            schools: {
                type: Array,
            },
            loading: {
              type: Boolean
            }
        },
        mounted() {
            this.filteredCourses = [...this.courses];
            this.searchChange();
            this.selectedSchool = null
        },
        watch: {
            courses: function(newVal, oldVal) {
                this.searchChange()
            },
            selectedSchool: function(newValue) {
                this.updateSelectedSchool(newValue);
            },
        },
        methods: {
            searchChange() {
                const searchTerm = this.searchcourse.filter.trim().toLowerCase();
                if (searchTerm) {
                    this.filteredCourses = this.courses.filter((course) => {
                        return (
                            course.nome.toLowerCase().includes(searchTerm)
                        );
                    });
                } else {
                    this.filteredCourses = [...this.courses];
                }
            },
            formatDate(dateTime) {
                return moment(dateTime).format('D/M/YYYY');
            },
            updateSelectedSchool(newValue) {
              console.log("scuola cambiata");
              const selectedSchoolObj = this.schools.find(school => school.name === newValue);

              if (selectedSchoolObj) {
                // Update the Vuex store with selectedSchool's name and ID
                this.store.state.global.idSelectedSchool = selectedSchoolObj.id
                this.store.state.global.selectedSchool = newValue
                this.$emit('update-school', selectedSchoolObj.id);
                console.log(this.store.state.global.idSelectedSchool);
              }
            },
        }
    }
</script>

<template>
    <div class="p-table-container" >
      <!-- Parte iniziale -->
      <div class="top-container">
        <Button
          buttoncolor="black"
          :buttontext="$t('agg_corso')"
          @btn-click="this.store.dispatch('toggleAddCourseForm')"
          :aria-label="$t('agg_corso')"
          role="button"
          v-if="this.store.state.global.selectedSchool"
        />
        <input
          class="search"
          @input="searchChange"
          type="search"
          name="searchcourse"
          id="searchcourse"
          :placeholder="$t('cerca_corso')"
          v-model.trim="searchcourse.filter"
          :aria-label="$t('cerca_corso')"
          role="search"
          style="height: 50px;"
          v-if="this.store.state.global.selectedSchool"
        />
        <v-select
            :label="$t('seleziona_scuola')"
            :items="this.schools.map(school => school.name)"
            style="width: 200px; height: 55px;"
            variant="outlined"
            v-model="selectedSchool"
            @change="updateSelectedSchool"
            @input="updateSelectedSchool"
        ></v-select>
      </div>
  
      <!-- Tabella -->
      <div v-if="this.store.state.global.selectedSchool">
        <v-skeleton-loader 
                boilerplate
                :loading="loading"
                type="table-thead, table-tbody, table-tfoot"
                width="700"
            >
          <v-data-table :items="this.filteredCourses" :headers="headers"  style="outline: 1px solid rgba(0,0,0,0.2); border-radius: 10px; max-height: 500px;">
            <template v-slot:item.id="{ item }">
              <div class="btn-group">
                <Button
                  buttoncolor="#fe2315"
                  @btn-click="$emit('delete-course', item.id)"
                  :aria-label="$t('elimina_corso')"
                  role="button"
                >
                  <template #icon>
                    <Icon color="black" icon="typcn:delete-outline" width="30" height="30" />
                  </template>
                  <template #tooltip>
                    <v-tooltip activator="parent" location="bottom">
                      {{ $t('elimina') }}
                    </v-tooltip>
                  </template>
                </Button>
                <Button
                  buttoncolor="grey"
                  @btn-click="this.store.dispatch('toggleEditCourseForm', [item])"
                  :aria-label="$t('modifica_corso')"
                  role="button"
                >
                  <template #icon>
                    <Icon color="black" icon="tabler:edit" width="30" height="30" />
                  </template>
                  <template #tooltip>
                    <v-tooltip activator="parent" location="bottom">
                      {{ $t('modifica') }}
                    </v-tooltip>
                  </template>
                </Button>
                <Button :buttontext="$t('vedi_docenze')" role="button" buttoncolor="grey" :to="'/assignments?courseId='+item.id+'&courseName='+item.nome"/>
              </div>
            </template>
          </v-data-table>
        </v-skeleton-loader>
      </div>
      <div v-else>{{ $t('seleziona_scuola') }}</div>
    </div>
  </template>

<style scoped>
    .p-table-container{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
    }

    .search {
        width: 200px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 10px;
        padding-left: 10px;
        outline: solid 1px rgba(0,0,0,0.3);
        border-radius: 5px
    }
    .btn-group{
        margin-left:20px;
        width: fit-content;
        column-gap: 10px;
        /* outline: black 1px solid; */
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .top-container{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 20px;
    }
</style>
<script>
    import { Icon } from '@iconify/vue';
    import Button from './Button.vue';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n'
    const moment = require('moment');
    export default {
        name: "LecturePresencesTable",
        components: {
            Icon,
            Button,
        },
        setup() {
            const { t } = useI18n() // use as global scope
            return {
                t
            }
        },
        data() {
            return {
                store: useStore(),
                headers: [
                    { title: this.$t('nome_corso'), value: 'module.nome', sortable: true },
                    { title: this.$t('orario_presenza'), value: 'created', sortable: true },
                    { title: this.$t('prove_richieste'), value: 'module.stepsRequired', sortable: false },
                    { title: this.$t('prove'), value: 'proofs', sortable: false },
                ],
            }
        },
        props: {
            presences: {
                type: Array,
            }
        },
        methods: {
            formatDateTime(dateTime) {
                return moment(dateTime).format('YYYY-MM-DD HH:mm:ss');
            },
        },
    }
</script>

<template>
    <div class="p-table-container">
       <div>
        <v-data-table :items="this.presences" :headers="headers" style="outline: 1px solid rgba(0,0,0,0.2); border-radius: 10px; max-height: 420px; width: fit-content; min-width: 854px;">
            <template v-slot:item.created="{ item }">
                <span>{{ formatDateTime(item.created) }}</span>
            </template>
            <template v-slot:item.module.stepsRequired="{ item }">
                <p v-if="item.module.stepsRequired.length > 0" v-for="step in item.module.stepsRequired" style="display: flex;">
                    <span style="font-weight: 600;">- {{ step.name }}</span>
                </p>
                <p v-else style="font-weight: 600;">{{ $t('no_prove_richieste') }}</p>
            </template>
            <template v-slot:item.proofs="{ item }">
                <p v-if="item.proofs.length > 0" v-for="proof in item.proofs" style="display: flex;">
                    <span v-if="proof.checkStep.name === 'login'">
                        <span style="font-weight: 600;">- Login: </span>
                        <span v-if="!proof.presenceContext.studentData">{{ $t('nessuna_prova') }}</span>
                        <span v-else>
                            {{ proof.presenceContext.studentData }}
                        </span>
                    </span>
                    <span v-if="proof.checkStep.name === 'captcha'"> 
                        <span style="font-weight: 600;">- Captcha: </span>
                        <span v-if="!proof.presenceContext.errors">{{ $t('nessuna_prova') }}</span>
                        <span v-else>
                            {{ proof.presenceContext.errors }} {{ $t('errore_i') }}
                        </span>
                        <br>
                        <span>inizio: </span>
                        <span v-if="!proof.presenceContext.startTime">{{ $t('nessuna_prova') }}</span>
                        <span v-else>
                            {{ formatDateTime(proof.presenceContext.startTime) }}
                        </span>
                        <br>
                        <span>fine: </span>
                        <span v-if="!proof.presenceContext.finishTime">{{ $t('nessuna_prova') }}</span>
                        <span v-else>
                            {{ formatDateTime(proof.presenceContext.finishTime) }}
                        </span>
                    </span>
                    <span v-if="proof.checkStep.name === 'email'">
                        <span style="font-weight: 600;">- Email: </span>
                        <span v-if="!proof.presenceContext.errors">{{ $t('nessuna_prova') }}</span>
                        <span v-else>
                            {{ proof.presenceContext.errors }} {{ $t('errore_i') }}
                        </span>
                        <br>
                        <span>inizio: </span>
                        <span v-if="!proof.presenceContext.startTime">{{ $t('nessuna_prova') }}</span>
                        <span v-else>
                            {{ formatDateTime(proof.presenceContext.startTime) }}
                        </span>
                        <br>
                        <span>fine: </span>
                        <span v-if="!proof.presenceContext.finishTime">{{ $t('nessuna_prova') }}</span>
                        <span v-else>
                            {{ formatDateTime(proof.presenceContext.finishTime) }}
                        </span>
                    </span>
                </p>
                <p v-else style="font-weight: 600;">{{ $t('no_prove') }}</p>
            </template>
        </v-data-table>
        </div>
    </div>
</template>

<style scoped>
    .p-table-container{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
        margin-bottom: 50px;
    }
</style>
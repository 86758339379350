<script>
    import TeachersTable from '../components/TeachersTable.vue';
    import AddTeacherForm from '../components/AddTeacherForm.vue';
    import EditTeacherForm from '../components/EditTeacherForm.vue';
    const axios = require('axios');
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex';
    export default {
        name: "TeachersView",
        components: {
            TeachersTable,
            AddTeacherForm,
            EditTeacherForm
        },
        data() {
            return {
                loading: true,
                teachers: [],
                store: useStore(),
                schools: [],
            }
        },
        setup() {
            const { t } = useI18n() // use as global scope
            return {
                t
            }
        },
        async created() {
            try {
                const me = await this.fetchMe();
                console.log(me);
                this.store.state.global.idSelectedSchool = null;
                this.store.state.global.selectedSchool = null;
                if(me.adminInfo){
                    this.schools = me.adminInfo.schools
                    if(this.store.state.global.idSelectedSchool) {
                       this.updateTeachers()
                    }
                }
            } catch (error) {
                // Handle the error if needed
                console.error('Error in created hook:', error.message);
            }
        },
        methods: {
            // fetch dei dati nel file db.json dell'array teachers
            async fetchTeachers() {
                const apiUrl = `${this.$apiBaseUrl}/school/${this.store.state.global.idSelectedSchool}/professors`;
                const bearerToken = this.$keycloak.token;

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    const response = await axios.get(apiUrl, config);
                    console.log('Response:', response.data);
                    const reversed = response.data.reverse();
                    return reversed;
                } catch (error) {
                    console.error('Error:', error.message);
                }
            },
            async updateTeachers() {
                this.teachers = await this.fetchTeachers();
                setInterval(() => {
                    this.loading = false
                }, 300);
            },
            async fetchMe() {
                console.log("entered fetchMe");
                const apiUrl = `${this.$apiBaseUrl}/users/me`;
                const bearerToken = this.$keycloak.token;

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    const response = await axios.get(apiUrl, config);
                    console.log('Response:', response.data);
                    return response.data;
                } catch (error) {
                    console.error('Error:', error.message);
                }
            },
            // funzione di fetch per prendere i teachers dal db
            async fetchTeacher(id) {
                const apiUrl = `${this.$apiBaseUrl}/professor/${id}`;
                const bearerToken = this.$keycloak.token;

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    const response = await axios.get(apiUrl, config);
                    console.log('Response:', response.data);
                    return response.data;
                } catch (error) {
                    console.error('Error:', error.message);
                }
            },

            async fetchAssignmentsTeacher(id) {
                const apiUrl = `${this.$apiBaseUrl}/docenza/professore/${id}`;
                const bearerToken = this.$keycloak.token;

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    const response = await axios.get(apiUrl, config);
                    console.log('Response:', response.data);
                    const data = response.data;
                    return data.length > 0;
                } catch (error) {
                    console.error('Error:', error.message);
                }
            },
            
            async createTeacher(teacher) {
                // Verifica se esiste già un insegnante con la stessa userEmail
                console.log(teacher, "teacher");
                const teacherExists = this.teachers.some(existingTeacher => existingTeacher.userEmail === teacher.userEmail);
                console.log(teacherExists, "teacherExists")
                if (teacherExists) {
                    this.store.dispatch('showAlert' , [this.$t('errore'), this.$t('errore_professore_esistente'), 5000, "#c42116", "bxs:error"])
                    return; // Non creare un nuovo insegnante se ne esiste già uno con la stessa userEmail
                }

                const apiUrl = `${this.$apiBaseUrl}/professor`;
                console.log(apiUrl, "apiUrl");
                const bearerToken = this.$keycloak.token;
                console.log(bearerToken, "bearerToken");

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                console.log(config, "config");

                try {
                    const response = await axios.post(apiUrl, teacher, config);
                    console.log(response, "response");
                    console.log("succes");
                    console.log('Teacher created:', response.data);
                    this.teachers = await this.fetchTeachers();
                    this.store.dispatch('toggleAddTeacherForm')
                    // modificare testi alert !!!!!!!!!!
                    this.store.dispatch('showAlert' , [this.$t('successo'), `${this.$t('successo_creazione_professore')}`, 5000, "#04c717", "ep:success-filled"])
                } catch (error) {
                    console.log(error, "error");
                    this.store.dispatch('showAlert' , [this.$t('errore'), `${this.$t('errore_creazione_professore')} ${error.message}`, 5000, "#c42116", "bxs:error"])
                }
                console.log("dopo try");
            },
            async editTeacher(newTeacher) {
                // Estrai le informazioni necessarie da newTeacher
                const [updatedTeacher, userId] = newTeacher;

                // Verifica se esiste già un insegnante con la stessa userEmail (escludendo il teacher corrente)
                const teacherExists = this.teachers.some(existingTeacher => 
                    existingTeacher.userEmail === updatedTeacher.userEmail && existingTeacher.userId !== userId
                );

                if (teacherExists) {
                    this.store.dispatch('showAlert' , [this.$t('errore'), this.$t('errore_professore_esistente'), 5000, "#c42116", "bxs:error"])
                    return; // Non modificare l'insegnante se ne esiste già uno con la stessa userEmail
                }

                const apiUrl = `${this.$apiBaseUrl}/professor/${userId}/${this.store.state.global.idSelectedSchool}`;
                const bearerToken = this.$keycloak.token;

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    const response = await axios.put(apiUrl, updatedTeacher, config);
                    console.log('Teacher updated:', response.data);

                    this.teachers = await this.fetchTeachers();
                    this.store.dispatch('toggleEditTeacherForm')
                    // modificare testi alert !!!!!!!!!!
                    this.store.dispatch('showAlert' , [this.$t('successo'), `${this.$t('successo_modifica_professore')}`, 5000, "#04c717", "ep:success-filled"])
                } catch (error) {
                    this.store.dispatch('showAlert' , [this.$t('errore'), `${this.$t('errore_modifica_professore')} ${error.message}`, 5000, "#c42116", "bxs:error"])
                }
            },
            async deleteTeacher(id) {
                // Controlla se il teacher ha docenze
                const hasAssignments = await this.fetchAssignmentsTeacher(id);

                if (hasAssignments) {
                    this.store.dispatch('showAlert' , [this.$t('errore'), this.$t('errore_docenze_professore'), 5000, "#c42116", "bxs:error"])
                    return; // Non eliminare l'insegnante se ha docenze
                }

                if(confirm(this.$t('conferma_eliminazione_professore'))){
                    const apiUrl = `${this.$apiBaseUrl}/professor/${id}/${this.store.state.global.idSelectedSchool}`;
                    const bearerToken = this.$keycloak.token;

                    const config = {
                        headers: {
                            'Authorization': `Bearer ${bearerToken}`,
                        },
                    };

                    try {
                        // Effettua la richiesta DELETE
                        const response = await axios.delete(apiUrl, config);
                        console.log('Teacher deleted:', response.data);

                        this.teachers = await this.fetchTeachers();
                        // modificare testi alert !!!!!!!!!!
                        this.store.dispatch('showAlert' , [this.$t('successo'), `${this.$t('successo_eliminazione_professore')}`, 5000, "#04c717", "ep:success-filled"])
                    } catch (error) {
                        this.store.dispatch('showAlert' , [this.$t('errore'), `${this.$t('errore_eliminazione_professore')} ${error.message}`, 5000, "#c42116", "bxs:error"])
                    }
                }
            },
        },
    }
</script>

<template>
    <div style="margin-top: 47px; margin-bottom: 50px; min-height: 400px;">
        <div v-show="this.store.state.teachers.ariaShowTeacher">
            <TeachersTable :schools="schools" :loading="loading" :teachers="teachers" @update-school="updateTeachers" @delete-teacher="deleteTeacher"/>
        </div>
    </div>
    <div v-show="this.store.state.teachers.showAddTeacherForm" class="form-container">
        <AddTeacherForm @create-teacher="createTeacher"/>
    </div>
    <div v-show="this.store.state.teachers.showEditTeacherForm" class="form-container">
        <EditTeacherForm @edit-teacher="editTeacher" :editingTeacher="this.store.state.teachers.editingTeacher"/>
    </div>
</template>


<style scoped>
    .form-container{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 30px;
        align-items: center;
        position: absolute;
        top: 0;
        background-color: rgba(0,0,0,0.6);
        z-index: 100;
        backdrop-filter: blur(2px);
    }
</style>
<template>
  <div v-if="this.store.state.global.authorized" style="margin-top: 50px; margin-bottom: 50px; width: 100%; display: flex; flex-direction: column; justify-content: center; align-items: center; line-break: break-all; row-gap: 10px;">
   <h1 style="max-width: 100%; text-align: center; line-break: break-all;">{{ $t('benvenuto') }} {{ this.store.state.global.userData.lastname }} {{ this.store.state.global.userData.firstname }}</h1>
   <div v-if="!(this.store.state.global.userData.roles.includes('ROLE_PROFESSOR') || this.store.state.global.userData.roles.includes('ROLE_ADMIN'))" style="display: flex; flex-direction: column; width: 100%; justify-content: center; align-items: center;">
      <p>{{ $t('sei_admin_docente') }} {{ $t('contattaci') }}<span style="color: black;"><a href="mailto:info@coderit.it" style="text-decoration: none; color: black; font-weight: 600;">info@coderit.it</a></span></p>
   </div>
  </div>
  <div class="header-img-container">
    <div class="header-img-text-container">
      <h1 class="header-title">{{ $t('header_titolo') }}</h1>
      <p class="header-text">{{ $t('header_testo') }}</p>
      <Button v-if="!this.store.state.global.authorized" buttoncolor="white" :buttontext="$t('inizia') + ' ➠'" style="margin-top: 10px; width: fit-content;" @click="login" class="header-button"/>
    </div>
    <div class="contact-conatiner" v-if="!this.store.state.global.authorized">
      <div class="contact-conatiner-text-container">
        <h1 class="contact-conatiner-title">{{ $t('titolo_contatto') }}</h1>
        <h2 class="contact-conatiner-text">{{ $t('testo_contatto') }}<span style="color: white;"><a href="mailto:info@coderit.it" style="text-decoration: none; color: white;">info@coderit.it</a></span></h2>
      </div>
    </div>
  </div>

  <div class="marketing-row">
    <div class="marketing-col">
      <h2 class="marketing-col-title">{{ $t('affidibilita_titolo') }}</h2>
      <p class="marketing-col-text">{{ $t('affidibilita_testo') }}</p>
    </div>
    <div class="marketing-col">
      <h2 class="marketing-col-title">{{ $t('sicurezza_titolo') }}</h2>
      <p class="marketing-col-text">{{ $t('sicurezza_testo') }}</p>
    </div>
    <div class="marketing-col" style="justify-content: flex-start;">
      <h2 class="marketing-col-title">{{ $t('facilita_titolo') }}</h2>
      <p class="marketing-col-text">{{ $t('facilita_testo') }}</p>
    </div>
  </div>

  <v-divider class="border-opacity-100" color="grey" style="margin: 50px; width: 90%;"></v-divider>

  <h2 class="carousel-title">{{ $t('carousel_titolo') }}</h2>

  <v-carousel style="width: 90%;" class="carousel">
    <v-carousel-item
      :src="EntiFormativiImage"
      cover
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: left;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          row-gap: 15px;
        "
      >
        <h2 class="carousel-slide-title">{{ $t('enti_formativi_titolo') }}</h2>
        <p class="carousel-slide-text" style="color: white">{{ $t('enti_formativi_testo') }}</p>
      </div>
    </v-carousel-item>
    
    <v-carousel-item
      :src="CorsistiImage"
      cover
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: left;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          row-gap: 15px;
        "
      >
        <h2 class="carousel-slide-title">{{ $t('corsisti_titolo') }}</h2>
        <p class="carousel-slide-text" style="color: white">{{ $t('corsisti_testo') }}</p>
      </div>
    </v-carousel-item>

    <v-carousel-item
      :src="DocentiImage"
      cover
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: left;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          row-gap: 15px;
        "
      >
        <h2 class="carousel-slide-title">{{ $t('docenti_titolo') }}</h2>
        <p class="carousel-slide-text" style="color: white">{{ $t('docenti_testo') }}</p>
      </div>
    </v-carousel-item>

    <v-carousel-item
      :src="ElearingImage"
      cover
    >
      <div
        style="
          display: flex;
          flex-direction: column;
          justify-content: center;
          align-items: left;
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          row-gap: 15px;
        "
      >
        <h2 class="carousel-slide-title">{{ $t('piattaforme_e_learning_titolo') }}</h2>
        <p class="carousel-slide-text" style="color: white">{{ $t('piattaforme_e_learning_testo') }}</p>
      </div>
    </v-carousel-item>
  </v-carousel>
  
  <v-divider class="border-opacity-100" color="grey" style="margin: 50px; width: 90%;"></v-divider>

  <div class="row-featurette">
    <div class="featurette-col-text-container">
      <h2 class="featurette-col-title">{{ $t('titolo_featurette_1') }}</h2>
      <p class="featurette-col-text">{{ $t('testo_featurette_1') }}</p>      
    </div>
    <div class="featurette-col-image">
      <img :src="QrCodeImage" alt="">
    </div>
  </div>
  
  <v-divider class="border-opacity-100" color="grey" style="margin: 50px; width: 90%;"></v-divider>

  <div class="row-featurette">
    <div class="featurette-col-text-container">
      <h2 class="featurette-col-title">{{ $t('titolo_featurette_2') }}</h2>
      <p class="featurette-col-text">{{ $t('testo_featurette_2') }}</p>        
    </div>
    <div class="featurette-col-image">
      <img :src="AnalisiImage" alt="">
    </div>
  </div>
  
  <v-divider class="border-opacity-100" color="grey" style="margin: 50px; width: 90%;"></v-divider>

</template>

<script>
import { useStore } from 'vuex';
import { useI18n } from 'vue-i18n'
import Button from './Button.vue';
import AnalisiImage from '@/assets/analisi-e1e9e3e5.png';
import QrCodeImage from '@/assets/qrcode-21515a71.png';
import CorsistiImage from '@/assets/carousel-corsisti.png';
import DocentiImage from '@/assets/carousel-docenti.png';
import ElearingImage from '@/assets/carousel-e-learning.jpg';
import EntiFormativiImage from '@/assets/carousel-enti-formativi.png';
export default {
  name: 'HelloWorld',
  setup() {
    const { t } = useI18n()
    return {
      store: useStore(),
      t
    }
  },
  components: {
    Button
  },
  data() {
    return {
      AnalisiImage: AnalisiImage,
      QrCodeImage: QrCodeImage,
      CorsistiImage: CorsistiImage,
      DocentiImage: DocentiImage,
      EntiFormativiImage: EntiFormativiImage,
      ElearingImage: ElearingImage,
    }
  },
  methods: {
    login () {
      if (this.$keycloak) {
          this.$keycloak.login();
      } else {
          console.error('Keycloak not initialized yet.');
          // Handle the situation accordingly, e.g., redirect to a login page.
      }
    },
  },
}
</script>

<style scoped>

  .contact-conatiner {
    width: 520px;
    height: 300px;
    display: flex;
    row-gap: 15px;
    justify-content: center;
    align-items: center;
    background-color: dodgerblue;
    padding: 15px;
    border-radius: 15px;
    margin-left: 130px;
  }

  .contact-conatiner-text-container {
    width: 460px;
  }
  .header-img-container {
    width: 100%;
    height: 647px;
    padding-right: 100px;
    padding-left: 100px;
    display: flex;
    /* justify-content: space-around; */
    align-items: center;
    background-image: url("@/assets/header-image.png");
    background-size: cover;
  }

  .header-title {
    font-size: 2.5rem;
    color: white;
    width: 500px;
  }

  .header-text {
    font-size: 20px;
    color: white;
    width: 520px;
  }

  .marketing-row {
    margin-top: 50px;
    width: 90%;
    display: flex;
    column-gap: 20px;
    align-items: center;
    justify-content: center;
}

  .marketing-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 33%;
    height: 100%;
  }

  .marketing-col-title {
    color: dodgerblue;
  }
  .marketing-col-text {
    /* text-align: center; */
    margin-top:20px
  }

  .carousel-title {
    width: 90%;
    color: dodgerblue;
    font-size: 2rem;
    margin-bottom: 10px;
  }

  .header-button:hover {
    background-color: dodgerblue !important;
  }
  .header-button{
    font-size: .7rem !important;
  }

  .row-featurette {
    width: 90%;
    display: flex;
    column-gap: 20px;
}

.featurette-col-image {
    width: 40%;
    height: fit-content;
    display: flex;
    align-items: center;
    justify-content: center;
}

.featurette-col-image img {
    width: 100%;
    height: auto;
}

p.featurette-col-text {
    font-size: 1.2rem;
}

.featurette-col-text-container {
    width: 55%;
    display: flex;
    flex-direction: column;
    row-gap: 30px;
}

h2.featurette-col-title {
    font-size: 2.2rem;
    color: dodgerblue;
}

.carousel-slide-title {
  color: white; 
  font-size: 3rem;
}

@media screen and (max-width: 1290px){

  .header-img-container {
    flex-direction: column;
    justify-content: center;
  }

  .header-img-text-container {
    margin-top: 20px;
  }

  .contact-conatiner {
    margin-left: 0;
    margin-top: 50px;
  }
  .row-featurette{
    flex-direction: column;
  }

  .featurette-col-text-container {
      width: 100%;
      margin-bottom: 50px;
    }
    
    .featurette-col-image {
      width: 80%;
      justify-self: center;
      align-self: center;
    }
}

@media screen and (max-width: 710px) {
    .header-img-container {
      padding: 0;
      height: 450px;
    }

    .contact-conatiner {
      width: 260px;
      height: 170px;
    }

    .contact-conatiner-text-container {
      width: 240px;
    }

    .contact-conatiner-text {
      font-size: 1rem;
    }

    .contact-conatiner-title {
      font-size: 1.2rem;
    }

    .header-title {
      font-size: 1.5rem;
      color: white;
      width: 300px;
    }

    .header-text {
      font-size: 0.8rem;
      color: white;
      max-width: 300px;
      width: fit-content;
    }

    .marketing-col-text {
      font-size: 0.8rem;
    }

    .marketing-row{
      row-gap: 10px;
      flex-direction: column;
    }

    .marketing-col {
      height: fit-content;
      width: 100%;
    }

    .carousel-slide-title {
      font-size: 1.2rem;
    }
    
    .carousel-slide-text {
      display: none;
    }

    .carousel {
      height: 300px !important;
      text-align: center;
    }

    .featurette-col-title {
      font-size: 1.8rem !important;
    }

  }

  @media screen and (max-width: 540px){
    .featurette-col-text {
      font-size: 0.8rem !important;
    }
  }
</style>
<script>
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n'
    import HereIamLogo from '@/assets/here-i-am-logo.svg';
    export default {
        // eslint-disable-next-line vue/multi-word-component-names
        name: "Header",
        setup() {
            const store = useStore();
            const { t } = useI18n() // use as global scope
            return {
                store,
                t
            };
        },
        data() {
            return {
                language: null,
                HereIamLogo: HereIamLogo,
                usersLinks: [this.$t('professori'), this.$t('admin')],
                coursesLinks: [this.$t('corsi'),this.$t('docenze'), this.$t('presenze')],
            }
        },
        methods: {
            logout() {
                console.log('Attempting to logout. this.$keycloak:', this.$keycloak);
                if (this.$keycloak) {
                    this.$keycloak.logout();
                } else {
                    console.error('Keycloak not initialized yet.');
                    // Handle the situation accordingly, e.g., redirect to a login page.
                }
            },
            login () {
                if (this.$keycloak) {
                    this.$keycloak.login();
                } else {
                    console.error('Keycloak not initialized yet.');
                    // Handle the situation accordingly, e.g., redirect to a login page.
                }
            },
            changeLanguage() {
                localStorage.setItem("lang", this.language)
                window.location.reload()
            },
            getPageLink(item) {
                // Aggiungi la logica per determinare il link della pagina in base all'elemento
                // Puoi utilizzare un oggetto di mapping o altri criteri
                if (item === this.$t('professori')) {
                    return "/teachers";
                } else if (item === this.$t('admin')) {
                    return "/admin";
                } else if (item === this.$t("corsi")) {
                    return "/courses";
                } else if (item === this.$t("docenze")) {
                    return "/assignments";
                } else if (item === this.$t("presenze")) {
                    return "/presences";
                }
                return "#"; // Link di fallback in caso di valore non riconosciuto
            },
        },
        created () {
            this.language = localStorage.getItem("lang")
        }
    }
</script>

<template>
    <v-container>
        <v-app-bar class="header">
            <v-img
                class="mx-2"
                :src="HereIamLogo"
                max-height="40"
                max-width="40"
                contain
            ></v-img>

            <v-toolbar-title class="ml-2">
                HERE I AM
            </v-toolbar-title>
            <template v-slot:append  v-if="!this.store.state.global.student">
                <div class="links" role="navigation">

                    <label for="lang-select" class="sr-only">{{ $t('seleziona_lingua') }}</label>
                    <select id="lang-select" class="lang-select" v-model="language" @change="changeLanguage" :aria-label="$t('seleziona_lingua')">
                        <option value="it">IT</option>
                        <option value="en">EN</option>
                    </select>

                    <v-menu>
                        <template v-slot:activator="{ props }">
                            <v-app-bar-nav-icon v-bind="props" style="border: 1px solid black;"></v-app-bar-nav-icon>
                        </template>

                        <v-list style="display: flex; flex-direction: column; row-gap: 10px;">
                            <v-btn variant="plain" color="black" :ripple="false" to="/" :aria-label="$t('link_pagina_home')">HOME</v-btn>

                            <v-divider v-if="this.store.state.global.userData.roles.includes('ROLE_SUPER_ADMIN')"></v-divider>

                            <v-btn variant="plain" color="black" :ripple="false" to="/schools" :aria-label="$t('link_pagina_scuole')" v-if="this.store.state.global.userData.roles.includes('ROLE_SUPER_ADMIN')">{{ $t('scuole') }}</v-btn>

                            <v-divider v-if="this.store.state.global.userData.roles.includes('ROLE_PROFESSOR') || this.store.state.global.userData.roles.includes('ROLE_PROFESSOR')"></v-divider>

                            <v-btn v-if="this.store.state.global.userData.roles.includes('ROLE_PROFESSOR')" variant="plain" color="black" :ripple="false" to="/teacher-assignments" :aria-label="$t('link_pagina_docenze')">{{ $t('mie_docenze') }}</v-btn>

                            <v-divider v-if="this.store.state.global.userData.roles.includes('ROLE_ADMIN')"></v-divider>

                            <v-menu v-if="this.store.state.global.userData.roles.includes('ROLE_ADMIN')">
                                <template v-slot:activator="{ props }">
                                    <v-btn
                                        variant="plain" 
                                        color="black"
                                        :ripple="false"
                                        v-bind="props"
                                    >
                                        {{ $t("gestione_utenze") }}
                                        <v-icon end> mdi-menu-down </v-icon>
                                    </v-btn>
                                </template>

                                <v-list class="bg-grey-lighten-3">
                                    <v-list-item
                                        v-for="item in usersLinks"
                                        :key="item"
                                        :to="getPageLink(item)"
                                    >
                                        {{ item }}
                                    </v-list-item>
                                </v-list>
                            </v-menu>

                            <v-divider v-if="this.store.state.global.userData.roles.includes('ROLE_ADMIN')"></v-divider>

                            <v-menu v-if="this.store.state.global.userData.roles.includes('ROLE_ADMIN')">
                                <template v-slot:activator="{ props }">
                                    <v-btn
                                        variant="plain" 
                                        color="black"
                                        :ripple="false"
                                        v-bind="props"
                                    >
                                        {{ $t("gestione_corsi") }}
                                        <v-icon end> mdi-menu-down </v-icon>
                                    </v-btn>
                                </template>

                                <v-list class="bg-grey-lighten-3">
                                    <v-list-item
                                        v-for="item in coursesLinks"
                                        :key="item"
                                        :to="getPageLink(item)"
                                    >
                                        {{ item }}
                                    </v-list-item>
                                </v-list>
                            </v-menu>

                            <v-divider></v-divider>

                            <v-btn variant="plain" color="black" :ripple="false" v-if="!this.store.state.global.authorized" @click="login" aria-label="Login">LOGIN</v-btn>
                            <v-btn variant="plain" color="black" :ripple="false" v-else @click="logout" aria-label="Logout">LOGOUT</v-btn>
                        </v-list>
                    </v-menu>

                    
                </div>
            </template>
        </v-app-bar>
    </v-container>

</template>

<style scoped>
    .links{
        width: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 20px;
    }
    .lang-select {
        width: 30px;
        height: fit-content;
        outline: 1px solid #000;
        border-radius: 2px;
        text-align: center;
        font-size: 12px;
    }

    .sr-only {
        position: absolute;
        width: 1px;
        height: 1px;
        margin: -1px;
        padding: 0;
        overflow: hidden;
        clip: rect(0, 0, 0, 0);
        border: 0;
    }

    .header {
        width: 100vw !important;
    }
</style>
<script>
    import { Icon } from '@iconify/vue';
    import Button from './Button.vue';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n'
    export default {
        name: "TeacherAssignmentsTable",
        components: {
            Icon,
            Button,
        },
        setup() {
            const { t } = useI18n() // use as global scope
            return {
                t
            }
        },
        data() {
            return {
                store: useStore(),
                headers: [
                    { title: this.$t('nome_corso'), value: 'nome', sortable: true},
                    { title: '', value: 'id', sortable: false },
                ],
                selectedSchool: null,
            }
        },
        props: {
            assignments: {
                type: Array,
            },
            schools: {
                type: Array,
            }, 
            loading: {
                type: Boolean,
            }
        },
        methods: {
            updateSelectedSchool(newValue) {
              console.log("scuola cambiata");
              const selectedSchoolObj = this.schools.find(school => school.name === newValue);

              if (selectedSchoolObj) {
                // Update the Vuex store with selectedSchool's name and ID
                this.store.state.global.idSelectedSchool = selectedSchoolObj.id
                this.store.state.global.selectedSchool = newValue
                this.$emit('update-school', selectedSchoolObj.id);
                console.log(this.store.state.global.idSelectedSchool);
              }
            },
        },
        mounted() {
            this.selectedSchool = null;
        },
        watch: {
            selectedSchool: function(newValue) {
                this.updateSelectedSchool(newValue);
            },
        }
    }
</script>

<template>
    <div class="p-table-container">
        <h2>{{ $t('mie_docenze') }}</h2>
        <div class="p-top-container"> 
            <v-select
                :label="$t('seleziona_scuola')"
                :items="this.schools.map(school => school.name)"
                style="width: 200px; height: 55px;"
                variant="outlined"
                v-model="selectedSchool"
                @change="updateSelectedSchool"
                @input="updateSelectedSchool"
            ></v-select>
        </div>
        <div v-if="this.store.state.global.selectedSchool">
            <v-skeleton-loader 
                boilerplate
                :loading="this.loading"
                max-width="360px"
                type="table-thead, table-tbody, table-tfoot"
            >
                <v-data-table :items="this.assignments" :headers="headers"  style="outline: 1px solid rgba(0,0,0,0.2); border-radius: 10px; max-height: 500px;">
                    <template v-slot:item.id="{ item }">
                        <div class="btn-group">
                            <Button :buttontext="$t('inizia_docenza')" role="button" buttoncolor="grey" :to="'/lecture-presences?lecture='+item.id"/>
                        </div>
                    </template>
                </v-data-table>
            </v-skeleton-loader>
        </div>
        <div v-else>{{ $t('seleziona_scuola') }}</div>
        
    </div>
</template>

<style scoped>
    .p-table-container{
        width: 100%;
        max-height: 88vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
    }
    .btn-group{
        margin-left:20px;
        width: fit-content;
        /* outline: black 1px solid; */
        display: flex;
        justify-content: space-between;
        align-items: center;
    }
    .p-top-container{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 20px;
    }
</style>
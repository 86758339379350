<script>
    import AssignmentsTable from '../components/AssignmentsTable.vue';
    import AddAssignmentForm from '../components/AddAssignmentForm.vue';
    import { useStore } from 'vuex';
    const axios = require('axios');
    export default {
        name: "AssignmentsView",
        components: {
            AssignmentsTable,
            AddAssignmentForm
        },
        data() {
            return {
                loading: true,
                assignments: [],
                teachers: [],
                courses: [],
                showAddAssignmentForm: false,
                store: useStore(),
                schools: [],
            }
        },
        async created() {
            const me = await this.fetchMe();
            console.log(me);
            this.store.state.global.idSelectedSchool = null;
            this.store.state.global.selectedSchool = null;
            if(me.adminInfo){
                this.schools = me.adminInfo.schools
                if(this.store.state.global.idSelectedSchool) {
                    this.updateAll()
                }
            }
        },
        methods: {
            async updateAll() {
                this.assignments = await this.fetchaAssignments();
                this.teachers = await this.fetchTeachers();
                this.courses = await this.fetchCourses();
                setInterval(() => {
                    this.loading = false
                }, 300);
            },
            async fetchMe() {
                console.log("entered fetchMe");
                const apiUrl = `${this.$apiBaseUrl}/users/me`;
                const bearerToken = this.$keycloak.token;

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    const response = await axios.get(apiUrl, config);
                    console.log('Response:', response.data);
                    return response.data;
                } catch (error) {
                    console.error('Error:', error.message);
                }
            },
            async fetchaAssignments() {
                const apiUrl = `${this.$apiBaseUrl}/docenza/${this.store.state.global.idSelectedSchool}`;
                const bearerToken = this.$keycloak.token;

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    const response = await axios.get(apiUrl, config);
                    console.log('Response: docenze', response.data);
                    const reversed = response.data.reverse();
                    return reversed;
                } catch (error) {
                    console.error('Error:', error.message);
                }
            },
            async fetchCourses() {
                const apiUrl = `${this.$apiBaseUrl}/school/${this.store.state.global.idSelectedSchool}/modules`;
                const bearerToken = this.$keycloak.token;

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    const response = await axios.get(apiUrl, config);
                    console.log('Response:', response.data);
                    const reversed = response.data.reverse();
                    return reversed;
                } catch (error) {
                    console.error('Error:', error.message);
                }
            },
            async fetchTeachers() {
                const apiUrl = `${this.$apiBaseUrl}/school/${this.store.state.global.idSelectedSchool}/professors`;
                const bearerToken = this.$keycloak.token;

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    const response = await axios.get(apiUrl, config);
                    console.log('Response:', response.data);
                    const reversed = response.data.reverse();
                    return reversed;
                } catch (error) {
                    console.error('Error:', error.message);
                }
            },
            async deleteAssignment(assignment) {
                
                const [id, userId] = assignment;
                if(confirm(this.$t('conferma_eliminazione_docenza'))){
                    const apiUrl = `${this.$apiBaseUrl}/docenza/${userId}/${id}`;
                    const bearerToken = this.$keycloak.token;

                    const config = {
                        headers: {
                            'Authorization': `Bearer ${bearerToken}`,
                        },
                    };

                    try {
                        // Effettua la richiesta DELETE
                        const response = await axios.delete(apiUrl, config);
                        console.log('Assignment deleted:', response.data);

                        this.assignments = await this.fetchaAssignments();
                        // modificare testi alert !!!!!!!!!!
                        this.store.dispatch('showAlert' , [this.$t('successo'), `${this.$t('successo_eliminazione_docenza')}`, 5000, "#04c717", "ep:success-filled"])
                    } catch (error) {
                        this.store.dispatch('showAlert' , [this.$t('errore'), `${this.$t('errore_eliminazione_docenza')} ${error.message}`, 5000, "#c42116", "bxs:error"])
                    }
                }
            },
            isAssignmentExists(userId, id) {
                let check = false;
                this.assignments.forEach(element => {
                    if(element.id === id && element.professore.userId === userId) {
                        console.log(element.id + " element.id - " + id + " id | " + element.professore.userId + " element.professore.userId - " + userId + " userId");
                        check = true
                    }
                });
                console.log(check + " check");
                return check
            },

            async createAssignment(assignment) {
                // Verifica se esiste già un collegamento tra insegnante e corso
                // console.log(assignment);
                const isAssignmentExisting = this.isAssignmentExists(assignment.professorId, assignment.moduleId);
                if (isAssignmentExisting) {
                    this.store.dispatch('showAlert' , [this.$t('errore'), this.$t('errore_docenza_esistente'), 5000, "#c42116", "bxs:error"])
                    return;
                }

                const apiUrl = `${this.$apiBaseUrl}/docenza`;
                const bearerToken = this.$keycloak.token;

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    const response = await axios.post(apiUrl, assignment, config);
                    console.log('Assignment created:', response.data);
                    this.assignments = await this.fetchaAssignments();
                    this.store.dispatch('toggleCreateAssignment')
                    // modificare testi alert !!!!!!!!!!
                    this.store.dispatch('showAlert' , [this.$t('successo'), `${this.$t('successo_creazione_docenza')}`, 5000, "#04c717", "ep:success-filled"])
                } catch (error) {
                    console.log(error)
                    this.store.dispatch('showAlert' , [this.$t('errore'), `${this.$t('errore_creazione_docenza')} ${error.message}`, 5000, "#c42116", "bxs:error"])
                }
            },
        },
    }
</script>

<template>
    <div style="width: fit-content; margin-top: 47px; margin-bottom: 50px; min-height: 400px;">
        <div v-show="this.store.state.assignments.ariaShowAssignment">
            <AssignmentsTable @update-school="updateAll" :schools="schools" :assignments="assignments" :teachers="teachers" :courses="courses" @delete-assignment="deleteAssignment"/>
        </div>    
    </div>
    <div v-show="this.store.state.assignments.showAddAssignmentForm" class="form-container">
        <AddAssignmentForm :teachers="teachers" :courses="courses" @create-assignment="createAssignment"/>
    </div>
</template>

<style scoped>
    .form-container{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 30px;
        align-items: center;
        position: absolute;
        top: 0;
        background-color: rgba(0,0,0,0.6);
        z-index: 100;
        backdrop-filter: blur(2px);
    }
</style>
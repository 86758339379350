<template>
  <v-app>
    <Header/>
    <div class="alert-container" v-show="this.store.state.global.showAlertState">
      <Alert :title="this.store.state.global.alertTitle" :text="this.store.state.global.alertText"  :color="this.store.state.global.alertColor"/>
    </div>
    <div class="container" role="main">
      <router-view/>
    </div>
    <div class="screen-alert-container" v-show="this.store.state.global.showScreenAlertState">
      <div class="screen-alert">
        <h2>{{ $t('titolo_alert_risoluzione') }}</h2>
        <p style="font-size: 1.3rem; font-weight: 600; width: 600px; text-align: center;">{{ $t('testo_alert_risoluzione') }}</p>
        <v-btn @click="this.store.state.global.showScreenAlertState = false">{{ $t('chiudi') }}</v-btn>
      </div>
    </div>
    <Footer></Footer>
  </v-app>
</template>

<script>
import Header from './components/Header.vue';
import Footer from './components/Footer.vue';
import Alert from './components/Alert.vue';
import { useStore } from 'vuex';
export default {
  setup() {
    const store = useStore();
    return {
      store
    }
  },
  name: 'App',
  components: {
    Header,
    Footer,
    Alert,
  },
  methods: {
  },
}
</script>

<style scoped>

.screen-alert-container {
  position: fixed;
  top: 0;
  width: 100%;
  height: 100vh;
  background-color: rgba(0,0,0,0.3);
  backdrop-filter: blur(4px);
  display: flex;
  justify-content: center;
  align-items: center;
  display: none;
}

.screen-alert {
  width: fit;
  height: fit-content;
  padding: 15px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 20px;
  background-color: red;
  row-gap: 15px;
}
.container {
    width: 100%;
    min-height: 100vh;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: 33px;
    padding: 0;
}

.alert-container {
    position: absolute;
    top: 30px;
    z-index: 9999;
    left: 50%;
    transform: translateX(-50%);
}

@media screen and (max-width: 1315px) {
  .screen-alert-container {
    display: flex;
  }
}
</style>

<script>
    import { Icon } from '@iconify/vue';
    import Button from './Button.vue';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n'
    export default {
        name: "AssignmentsTable",
        components: {
            Icon,
            Button,
        },
        setup() {
            const { t } = useI18n() // use as global scope
            return {
                t
            }
        },
        data() {
            return {
                store: useStore(),
                filteredAssignments: [],
                showFiltered: true,
                headers: [
                    { title: this.$t('nome_professore'), value: 'professore.name', sortable: true },
                    { title: this.$t('cognome_professore'), value: 'professore.surname', sortable: true },
                    { title: this.$t('email_professore'), value: 'professore.userEmail', sortable: true},
                    { title: this.$t('nome_corso'), value: 'nome', sortable: true },
                    { title: '', value: 'id', sortable: false },
                ],
                selectedSchool: null,
            }
        },
        props: {
            assignments: {
                type: Array,
            },
            teachers: {
                type: Array,
            },
            courses: {
                type: Array,
            },
            schools: {
                type: Array,
            },
            loading: {
                type: Boolean,
            }
        },
        methods: {
            resetFilter() {
                this.filteredAssignments = this.assignments;
                this.showFiltered = false;
            },
            filteredSearch() {
                if(this.$route.query.teacherId){
                    console.log(this.assignments, "assignments while filtering");
                    this.filteredAssignments = this.assignments.filter((assignment) =>
                    assignment.professore.userId == this.$route.query.teacherId);
                    console.log(this.filteredAssignments, "filteredAssignments after filtering");
                } else if(this.$route.query.courseId){
                    console.log("filtered by course id");
                    this.filteredAssignments = this.assignments.filter((assignment) =>
                    assignment.id == this.$route.query.courseId);
                    console.log(this.filteredAssignments, "filteredAssignments after filtering");
                } else {
                    this.filteredAssignments = this.assignments
                }
            },
            updateSelectedSchool(newValue) {
              console.log("scuola cambiata");
              const selectedSchoolObj = this.schools.find(school => school.name === newValue);

              if (selectedSchoolObj) {
                // Update the Vuex store with selectedSchool's name and ID
                this.store.state.global.idSelectedSchool = selectedSchoolObj.id
                this.store.state.global.selectedSchool = newValue
                this.$emit('update-school', selectedSchoolObj.id);
                console.log(this.store.state.global.idSelectedSchool);
              }
            },
        },
        mounted() {
            this.filteredAssignments = [...this.assignments];
            this.filteredSearch();
            this.selectedSchool = null;
        },
        // reazione ai cambiamenti dei props
        watch: {
            assignments: function(newVal, oldVal) {
                this.filteredSearch()
            },
            selectedSchool: function(newValue) {
                this.updateSelectedSchool(newValue);
            },
        },
    }
</script>

<template>
    <div class="p-table-container">
        <p v-if="this.store.state.global.selectedSchool" class="title">{{ $t('visualizzando') }} 
            <span v-if="this.showFiltered && this.$route.query.teacherName">{{ $t('docenze_di_docente') }} {{ this.$route.query.teacherName }}</span>
            <span v-if="this.showFiltered && this.$route.query.courseName">{{ $t('docenze_di_corso') }} {{ this.$route.query.courseName }}</span>
            <span v-if="!(this.showFiltered && this.$route.query.courseName) && !(this.showFiltered && this.$route.query.teacherName)">{{ $t('tutte_docenze') }} </span>
        </p>
        <div class="top-container">
            <Button v-if="this.store.state.global.selectedSchool" role="button" :aria-label="$t('agg_docenza')" buttoncolor="black" :buttontext="$t('agg_docenza')" @btn-click="this.store.dispatch('toggleCreateAssignment')"/>
            <v-select
                :label="$t('seleziona_scuola')"
                :items="this.schools.map(school => school.name)"
                style="width: 200px; height: 55px;"
                variant="outlined"
                v-model="selectedSchool"
                @change="updateSelectedSchool"
                @input="updateSelectedSchool"
            ></v-select>
            <Button v-if="this.showFiltered && (this.$route.query.teacherId || this.$route.query.courseId) && this.store.state.global.selectedSchool" role="button" :aria-label="$t('reset_filtro_docenze')" buttoncolor="black" :buttontext="$t('reset_filtro_docenze')" @btn-click="resetFilter"/>
        </div>
        <div v-if="this.store.state.global.selectedSchool">
            <v-skeleton-loader 
                boilerplate
                :loading="this.loading"
                max-width="360px"
                type="table-thead, table-tbody, table-tfoot"
            >
                <v-data-table :items="this.filteredAssignments" :headers="headers" style="outline: 1px solid rgba(0,0,0,0.2); border-radius: 10px; max-height: 500px;">
                    <template v-slot:item.id="{ item }">
                        <div class="btn-group">
                            <Button role="button" :aria-label="$t('elimina_docenza')" buttoncolor="#fe2315" @btn-click="$emit('delete-assignment', [item.id, item.professore.userId])">
                                <template #icon>
                                    <Icon color="black" icon="typcn:delete-outline" width="30" height="30" />
                                </template>
                                <template #tooltip>
                                    <v-tooltip activator="parent" location="bottom">
                                        {{ $t('elimina') }}
                                    </v-tooltip>
                                </template>
                            </Button>
                        </div>
                    </template>
                </v-data-table>
            </v-skeleton-loader>
        </div>
        <div v-else>{{ $t('seleziona_scuola') }}</div>
    </div>
</template>

<style scoped>
    .p-table-container{
        width: 100%;
        max-height: 88vh;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
    }
    .btn-group{
        margin-left:20px;
        width: fit-content;
        column-gap: 10px;
        /* outline: black 1px solid; */
        display: flex;
        justify-content: space-between;
        align-items: center;
    }

    .top-container{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 20px;
    }
    
    .title {
        font-size: 1.5rem;
    }
</style>
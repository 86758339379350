<script>
    import { Icon } from '@iconify/vue';
    import Button from './Button.vue';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n'
    import VueDatePicker from '@vuepic/vue-datepicker';
    import '@vuepic/vue-datepicker/dist/main.css'
    import * as XLSX from 'xlsx';
    const moment = require('moment');
    export default {
        name: "LecturePresencesTable",
        components: {
            Icon,
            Button,
            VueDatePicker,
        },
        setup() {
            const { t } = useI18n() // use as global scope
            return {
                t
            }
        },
        data() {
            return {
                filteredPresences: [],
                store: useStore(),
                selectedDate: null,
                selectedCourse: null,
                selectedSchool: null,
                headers: [
                    { title: this.$t('nome_corso'), value: 'module.nome', sortable: true },
                    { title: this.$t('orario_presenza'), value: 'created', sortable: true },
                    { title: this.$t('prove_richieste'), value: 'module.stepsRequired', sortable: false },
                    { title: this.$t('prove'), value: 'proofs', sortable: false },
                ],
            }
        },
        props: {
            presences: {
                type: Array,
            },
            courses: {
                type: Array,
            },
            schools: {
                type: Array,
            },
            loading: {
                type: Boolean,
            }
        },
        methods: {
            formatDateTime(dateTime) {
                // console.log(dateTime);
                return moment(dateTime).format('DD/MM/YYYY | HH:mm:ss');
            },
            formatDate(dateTime) {
                return moment(dateTime).format('D/M/YYYY');
            },
            checkDateInArray(selectedDate) {

                if(selectedDate){

                    console.log("---------------------------------------------------------------------------")
                    console.log("inizio checkDateInArray():");
                    const selectedDateStr = selectedDate.toLocaleDateString();
                    console.log("selectedDateStr", selectedDateStr);

                    this.filteredPresences = this.presences.filter((presence) => {
                        return (
                            this.formatDate(presence.created).includes(selectedDateStr)
                        );
                    });
                }

            },
            async selectChange(newCourse) {

                    console.log(this.presences.length, "this.presences.length");
                    let result = await this.updateSelectedCourse(newCourse)
                // if(this.presences.length != 0){
                    if(newCourse) {
                
                        console.log("---------------------------------------------------------------------------")
                        console.log("inizio selectChange():");

                        if(this.selectedDate === null){
                            
                            console.log("this.selectedDate === null | true");
                            console.log("data non selezionata");

                            this.filteredPresences = this.presences.filter((presence) => {
                                return (
                                    presence.module.nome.includes(newCourse)
                                );
                            });
                        } else {
                            console.log("this.selectedDate !== null | true");
                            console.log("data selezionata");
                            const selectedDateStr = this.selectedDate.toLocaleDateString();

                            this.filteredPresences = this.presences.filter((presence) => {
                                return (
                                    presence.module.nome.includes(newCourse) && 
                                    this.formatDate(presence.created).includes(selectedDateStr)
                                );
                            });

                        }
                    // }
                }

            },
            clearFilter() {
                this.selectedDate = null;
                this.selectedCourse = null;
                this.store.state.global.selectedCourse = null;
                this.store.state.global.idSelectedCourse = null;
                this.filteredPresences = [...this.presences]
            },
            downloadExcel() {
                const dataForExcel = this.filteredPresences.map(item => ({
                    'NOME CORSO': item.module.nome,
                    'ORARIO PRESENZA': this.formatDateTime(item.created),
                    'PROVE RICHIESTE': this.formatStepsRequired(item.module.stepsRequired),
                    'PROVE PRESENZA': this.formatProofs(item.proofs),
                }));

                const ws = XLSX.utils.json_to_sheet(dataForExcel);
                const wb = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(wb, ws, 'Sheet1');
                XLSX.writeFile(wb, 'presenze.xlsx');
            },
            formatStepsRequired(stepsRequired) {
                if (stepsRequired.length > 0) {
                    return stepsRequired.map(step => `- ${step.name}`).join('\n');
                } else {
                    return 'NESSUNA PROVA RICHIESTA';
                }
            },
            formatProofs(proofs) {
                if (proofs.length > 0) {
                    return proofs.map(proof => this.formatProof(proof)).join('\n');
                } else {
                    return 'NO PROVE';
                }
            },
            formatProof(proof) {
                switch (proof.checkStep.name) {
                    case 'login':
                    return `- Login: ${proof.presenceContext.studentData || this.$t('nessuna_prova')}`;

                    case 'captcha':
                    return (`- Captcha: ${proof.presenceContext.errors || this.$t('nessuna_prova')}`)+ "\n" + (`inizio: ${this.formatDateTime(proof.presenceContext.startTime)}`)+ "\n" + (`fine: ${this.formatDateTime(proof.presenceContext.finishTime)}`);

                    case 'email':
                    return (`- Email: ${proof.presenceContext.errors || this.$t('nessuna_prova')}`)+ "\n" + (`inizio: ${this.formatDateTime(proof.presenceContext.startTime)}`)+ "\n" + (`fine: ${this.formatDateTime(proof.presenceContext.finishTime)}`);

                    // Add additional cases for other checkStep names if needed

                    default:
                    return ''; // Handle other cases or return an empty string
                }
            },
            updateSelectedSchool(newValue) {
              console.log("scuola cambiata");
              const selectedSchoolObj = this.schools.find(school => school.name === newValue);
              this.store.state.global.idSelectedCourse = null
              this.store.state.global.selectedCourse = null
              this.clearFilter()
              if (selectedSchoolObj) {
                // Update the Vuex store with selectedSchool's name and ID
                this.store.state.global.idSelectedSchool = selectedSchoolObj.id
                this.store.state.global.selectedSchool = newValue
                this.$emit('update-school', selectedSchoolObj.id);
                console.log(this.store.state.global.idSelectedSchool);
              }
              return true;
            },
            updateSelectedCourse(newValue) {
                if(this.selectedCourse){
                    console.log("corso cambiato");
                    const selectedCourseObj = this.courses.find(course => course.nome === newValue);

                    if (selectedCourseObj) {
                        // Update the Vuex store with selectedCourse's name and ID
                        this.store.state.global.idSelectedCourse = selectedCourseObj.id
                        this.store.state.global.selectedCourse = newValue
                        this.$emit('update-course', selectedCourseObj.id);
                        console.log(this.store.state.global.idSelectedCourse);
                    }
                }
                return null
            },
        },
        mounted() {
            this.filteredPresences = [...this.presences]
            this.selectedSchool = null;
            this.selectedCourse = null;
        },
        watch: {
            presences: function(newValue) {
                this.filteredPresences = [...this.presences];
            },
            selectedDate(newDate) {
                this.checkDateInArray(newDate);
            },
            selectedCourse: function(newValue) {
                this.selectChange(newValue);
            },
            selectedSchool: function(newValue) {
                this.updateSelectedSchool(newValue);
            },
        },
    }
</script>

<template>
    <div class="p-table-container">
        <div style="width: 100%; display: flex; justify-content: center;">
            <div style="display: flex; flex-direction: column; row-gap: 20px; width: fit-content; justify-self: left; margin-right: 20px;">
                <v-date-picker
                    v-model="selectedDate"
                    style="border:1px solid black"
                    v-if="this.store.state.global.selectedCourse"
                ></v-date-picker>
            </div>
            <div style="width: fit-content;">
                <div style="width: 100%; height: 100%; display: flex; flex-direction: column; row-gap: 10px;">
                    <div class="p-top-container">
                        <div style="display: flex; column-gap: 20px; width: fit-content;">
                            
                            <v-select
                                :label="$t('seleziona_scuola')"
                                :items="this.schools.map(school => school.name)"
                                style="width: 200px; height: 55px;"
                                variant="outlined"
                                v-model="selectedSchool"
                                @change="updateSelectedSchool"
                                @input="updateSelectedSchool"
                            ></v-select>
                            
                            <v-select
                                :label="$t('seleziona_corso')"
                                :aria-label="$t('seleziona_corso')"
                                :items="this.courses.map(course => course.nome)"
                                style="width: 200px;"
                                variant="outlined"
                                v-model="selectedCourse"
                                v-if="this.store.state.global.selectedSchool"
                            ></v-select>
                            
                            <v-btn  v-if="this.store.state.global.selectedCourse" variant="outlined" style="height: 50px;" @click="downloadExcel">Download Excel</v-btn>
                        </div>
                        <Button  v-if="this.store.state.global.selectedCourse" role="button" buttoncolor="#fe2315" :buttontext="$t('rimuovi_filtri')" @click="clearFilter" style="width: fit-content; height: 58px;"/>
                    </div>
                    <div v-if="this.store.state.global.selectedSchool">
                        <div v-if="this.store.state.global.selectedCourse">
                            <v-skeleton-loader 
                                boilerplate
                                :loading="this.loading"
                                max-width="800px"
                                style="width: 100%; height: 100%;"
                                type="heading, table-tfoot, table-tfoot, table-tfoot"
                            >
                                <v-data-table :items="this.filteredPresences" :headers="headers" style="outline: 1px solid rgba(0,0,0,0.2); border-radius: 10px; max-height: 420px; width: fit-content; min-width: 854px;">
                                    <template v-slot:item.created="{ item }">
                                        <span>{{ formatDateTime(item.created) }}</span>
                                    </template>
                                    <template v-slot:item.module.stepsRequired="{ item }">
                                        <p v-if="item.module.stepsRequired.length > 0" v-for="step in item.module.stepsRequired" style="display: flex;">
                                            <span style="font-weight: 600;">- {{ step.name }}</span>
                                        </p>
                                        <p v-else style="font-weight: 600;">{{ $t('no_prove_richieste') }}</p>
                                    </template>
                                    <template v-slot:item.proofs="{ item }">
                                        <p v-if="item.proofs.length > 0" v-for="proof in item.proofs" style="display: flex;">
                                            <span v-if="proof.checkStep.name === 'login'">
                                                <span style="font-weight: 600;">- Login: </span>
                                                <span v-if="!proof.presenceContext.studentData">{{ $t('nessuna_prova') }}</span>
                                                <span v-else>
                                                    {{ proof.presenceContext.studentData }}
                                                </span>
                                            </span>
                                            <span v-if="proof.checkStep.name === 'captcha'"> 
                                                <span style="font-weight: 600;">- Captcha: </span>
                                                <span v-if="!proof.presenceContext.errors">{{ $t('nessuna_prova') }}</span>
                                                <span v-else>
                                                    {{ proof.presenceContext.errors }} {{ $t('errore_i') }}
                                                </span>
                                                <br>
                                                <span>inizio: </span>
                                                <span v-if="!proof.presenceContext.startTime">{{ $t('nessuna_prova') }}</span>
                                                <span v-else>
                                                    {{ formatDateTime(proof.presenceContext.startTime) }}
                                                </span>
                                                <br>
                                                <span>fine: </span>
                                                <span v-if="!proof.presenceContext.finishTime">{{ $t('nessuna_prova') }}</span>
                                                <span v-else>
                                                    {{ formatDateTime(proof.presenceContext.finishTime) }}
                                                </span>
                                            </span>
                                            <span v-if="proof.checkStep.name === 'email'">
                                                <span style="font-weight: 600;">- Email: </span>
                                                <span v-if="!proof.presenceContext.errors">{{ $t('nessuna_prova') }}</span>
                                                <span v-else>
                                                    {{ proof.presenceContext.errors }} {{ $t('errore_i') }}
                                                </span>
                                                <br>
                                                <span>inizio: </span>
                                                <span v-if="!proof.presenceContext.startTime">{{ $t('nessuna_prova') }}</span>
                                                <span v-else>
                                                    {{ formatDateTime(proof.presenceContext.startTime) }}
                                                </span>
                                                <br>
                                                <span>fine: </span>
                                                <span v-if="!proof.presenceContext.finishTime">{{ $t('nessuna_prova') }}</span>
                                                <span v-else>
                                                    {{ formatDateTime(proof.presenceContext.finishTime) }}
                                                </span>
                                            </span>
                                        </p>
                                        <p v-else style="font-weight: 600;">{{ $t('no_prove') }}</p>
                                    </template>
                                </v-data-table>
                            </v-skeleton-loader>
                        </div>
                        <div v-else>{{ $t('seleziona_corso') }}</div>
                    </div>
                    <div v-else>{{ $t('seleziona_scuola') }}</div>
                </div>
            </div>
        </div>
    </div>
</template>

<style scoped>
    .p-table-container{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
        margin-bottom: 50px;
    }
    .search {
        width: 200px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 10px;
        padding-left: 10px;
        outline: solid 1px rgba(0,0,0,0.3);
        border-radius: 5px
    }
    .p-top-container{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: space-between;
        column-gap: 20px;
    }
</style>
<template>
     <div style="margin-top: 47px; margin-bottom: 50px; min-height: 400px;">
        <div v-show="this.store.state.schools.ariaShowSchool">
            <SchoolsTable :loading="loading" :schools="schools"/>
        </div>
    </div>
    <div v-show="this.store.state.schools.showAddSchoolForm" class="form-container">
        <AddSchoolForm @create-school="createSchool"/>
    </div>
    <div v-show="this.store.state.schools.showEditSchoolForm" class="form-container">
        <EditSchoolForm @edit-school="editSchool" :editingSchool="this.store.state.schools.editingSchool"/>
    </div>
</template>

<script>
    import SchoolsTable from '../components/SchoolsTable.vue';
    import AddSchoolForm from '../components/AddSchoolForm.vue';
    import EditSchoolForm from '../components/EditSchoolForm.vue';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n'
    const axios = require('axios');
    export default {
        name: "SchoolsView",
        components: {
            SchoolsTable,
            AddSchoolForm,
            EditSchoolForm,
        },
        setup() {
        const { t } = useI18n() // use as global scope
            return {
                t
            }
        },
        data() {
            return {
                loading: true,
                store: useStore(),
                schools: [],
            }
        },
        async created() {
            try {
                this.schools = await this.fetchSchools();
                setInterval(() => {
                    this.loading = false
                }, 300);
            } catch (error) {
                // Handle the error if needed
                console.error('Error in created hook:', error.message);
            }
        },
        methods: {
            async fetchSchools() {
                const apiUrl = `${this.$apiBaseUrl}/school`;
                const bearerToken = this.$keycloak.token;

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    const response = await axios.get(apiUrl, config);
                    console.log('Response:', response.data);
                    const reversed = response.data.reverse();
                    return reversed;
                } catch (error) {
                    console.error('Error:', error.message);
                }
            },
            
            async createSchool(school) {
                // Verifica se esiste già una scuola con lo stesso nome
                console.log(school, "school");
                const schoolExist = this.schools.some(existingSchool => existingSchool.name === school.name);
                console.log(schoolExist, "schoolExist")
                if (schoolExist) {
                    this.store.dispatch('showAlert' , [this.$t('errore'), this.$t('errore_scuola_esistente'), 5000, "#c42116", "bxs:error"])
                    return; // Non creare una nuova scuola se ne esiste già una con lo stesso nome
                }

                const apiUrl = `${this.$apiBaseUrl}/school`;
                console.log(apiUrl, "apiUrl");
                const bearerToken = this.$keycloak.token;
                console.log(bearerToken, "bearerToken");

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                console.log(config, "config");

                try {
                    const response = await axios.post(apiUrl, school, config);
                    console.log(response, "response");
                    console.log("succes");
                    console.log('School created:', response.data);
                    this.schools = await this.fetchSchools();
                    this.store.dispatch('toggleCreateSchool')
                    // modificare testi alert !!!!!!!!!!
                    this.store.dispatch('showAlert' , [this.$t('successo'), `${this.$t('successo_creazione_scuola')}`, 5000, "#04c717", "ep:success-filled"])
                } catch (error) {
                    console.log(error, "error");
                    this.store.dispatch('showAlert' , [this.$t('errore'), `${this.$t('errore_creazione_scuola')} ${error.message}`, 5000, "#c42116", "bxs:error"])
                }
                console.log("dopo try");
            },
            async editSchool(school) {
                // Estrai le informazioni necessarie da school
                const [updatedSchool, id] = school;

                // Verifica se esiste già una scuola con lo stesso nome (escludendo la scuola corrente)
                const schoolExist = this.schools.some(existingSchool => 
                    existingSchool.name === updatedSchool.name && existingSchool.id !== id
                );

                if (schoolExist) {
                    this.store.dispatch('showAlert' , [this.$t('errore'), this.$t('errore_scuola_esistente'), 5000, "#c42116", "bxs:error"])
                    return; // Non modificare la scuola se ne esiste già una con lo stesso nome
                }

                const apiUrl = `${this.$apiBaseUrl}/school/${id}`;
                const bearerToken = this.$keycloak.token;

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    const response = await axios.put(apiUrl, updatedSchool, config);
                    console.log('School updated:', response.data);

                    this.schools = await this.fetchSchools();
                    this.store.dispatch('toggleEditSchool')
                    this.store.dispatch('showAlert' , [this.$t('successo'), `${this.$t('successo_modifica_scuola')}`, 5000, "#04c717", "ep:success-filled"])
                } catch (error) {
                    this.store.dispatch('showAlert' , [this.$t('errore'), `${this.$t('errore_modifica_scuola')} ${error.message}`, 5000, "#c42116", "bxs:error"])
                }
            },
        },
    }
</script>

<style  scoped>
.form-container{
    width: 100%;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    row-gap: 30px;
    align-items: center;
    position: absolute;
    top: 0;
    background-color: rgba(0,0,0,0.6);
    z-index: 100;
    backdrop-filter: blur(2px);
}
</style>
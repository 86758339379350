<script>
    import { Icon } from '@iconify/vue';
    import { useI18n } from 'vue-i18n'
    import { useStore } from 'vuex';
    export default {
        name: "Alert",
        components: {
            Icon,
        },
        pops: {
            title: {
                type: String
            },
            text: {
                type: String
            },
            color: {
                type: String
            },
        },
        setup() {
            const { t } = useI18n() // use as global scope
            return {
                t
            }
        },
        data() {
            return {
                store: useStore()
            }
        },
    }
</script>

<template>
    <v-alert class="alert"
        density="compact"
        :color="color"
        :title="title"
        :text="text"
        role="alert"
    >
        <Icon :icon="this.store.state.global.alertIcon" width="30" height="30" class="alert-icon" />
    </v-alert>
</template>

<style scoped>
    .alert{
        max-width: 500px;
        height: fit-content;
        padding-left: 60px;
        padding-right: 20px;
        position: relative;
        line-break: break-all;
    }
    .alert-icon {
        position: absolute;
        left: 15px;
        top: 50%;
        transform: translateY(-50%);
    }
    
</style>
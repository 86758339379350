<template>
    <div class="main-container">
        <div class="loading-container">
            <h1 v-show="this.loading" v-if="!this.courseName === ''">Benvenuto al corso di {{ this.courseName }}</h1>
            <h2>{{this.status}}</h2>
            <h2>{{this.status2}}</h2>
            <Icon v-if="this.loading" icon="svg-spinners:90-ring-with-bg" class="loading-svg"/>
            <div v-show="emailShow" class="email-verification-container">
                <h2>{{ $t('controlla_mail') }}</h2>
            </div>
            <div v-show="captchaShow" class="captcha-container">
                <div id="user-input" class="inline">
                    <input type="text"
                        id="submit"
                        placeholder="Captcha code" 
                        v-model="userCaptcha.filter"
                    />
                </div>
            
                <div class="inline" @click="generate">
                    RIGENERA CAPTCHA
                </div>
            
                <div id="image"
                    class="inline"
                    selectable="False">
                    {{ this.captchaGenerated }}
                </div>
                <input type="submit"
                    id="btn"
                    @click="printmsg" />
            
                <p id="key">{{ this.captchaStatus }}</p>
            </div>
        </div>
    </div>
</template>

<script>
    const axios = require('axios');
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n'
    import { Icon } from '@iconify/vue';
    export default {
        name: "StudentVerificationView",
        setup() {
            const { t } = useI18n() // use as global scope
            return {
                t
            }
        },
        data() {
            return {
                loading: true,
                courseName: "",
                status: "",
                userCaptcha: {
                    filter: ""
                },
                captchaGenerated: "",
                captchaStatus: "",
                status2: "",
                captchaShow: false,
                emailShow: false,
                userEmail: {
                    filter:""
                },
                emailStatus: "",
                store: useStore(),
                nextStep: null,
                emailErrors: 0,
                captchaErrors: 0,
                time: null
            };
        },
        components: {
            Icon,
        },
        async mounted() {
            this.status = this.$t('attendi')
            if(this.$route.query.sessionId){
                const sessionId = this.$route.query.sessionId;
                const random = this.$route.query.random;
                const currentStepId = this.$route.query.currentStepId;
                const presenceContextObj = {random: random}
                const newStep = {
                    sessionId: sessionId,
                    currentStepId: currentStepId,
                    presenceContext: presenceContextObj
                }
                const nextStep = await this.fetchNext(newStep);
                console.log("next step is ", nextStep)
                if(nextStep.nextStep === null) {
                    this.loading = false
                    this.status = this.$t('verificato')
                    this.emailShow = false
                    this.captchaShow = false
                    this.showBtn = false
                    localStorage.removeItem("sessionId")
                    localStorage.removeItem("nextStep")
                    localStorage.removeItem("prevStep")
                    localStorage.removeItem("prevStepProofs")
                // altrimenti esegue il prossimo
                } else {
                    this.nextStep = nextStep.nextStep.id
                    this.time = new Date();
                    this.doStep(nextStep.nextStep.name, nextStep.nextStep.id)
                }
            }
            // se il token non è nell'url viene rimandato alla pagina forbidden
            else if(this.$route.query.token){
                // se nel localStorage non c'è nextStep fa il fetch del firstStep
                if(!localStorage.getItem("nextStep")) {
                    console.log(this.$route.query.token, "this.$route.query.token mounted");
                    const firstStep = await this.fetchFirst(this.$route.query.token);
                    this.courseName = firstStep.moduleId;
                    console.log(firstStep, "firstStep");
                    setInterval(() => {
                        this.loading = false
                    }, 300);
                    // se non c'è uno step sucessivo esegue il prossimo
                    if(firstStep.nextStep != null){
                        localStorage.setItem("sessionId", firstStep.sessionId)
                        this.nextStep = firstStep.nextStep.id
                        this.time = new Date();
                        this.doStep(firstStep.nextStep.name)
                    // altrimenti comunica all'utente che è autenticato
                    } else {
                        this.loading = false
                        this.status = this.$t('verificato')
                        this.emailShow = false
                        this.captchaShow = false
                        this.showBtn = false
                        localStorage.removeItem("sessionId")
                        localStorage.removeItem("nextStep")
                        localStorage.removeItem("prevStep")
                        localStorage.removeItem("prevStepProofs")
                    }
                // altrimenti fa il fetch del nextStep
                } else {
                    let presenceContextObj = {}
                    if(this.$keycloak.token){
                        presenceContextObj = {
                            studentData: `${this.store.state.global.userData.firstname} ${this.store.state.global.userData.lastname} ${this.store.state.global.userData.email}`
                        }
                    }
                    if(localStorage.getItem("prevStep")){
                        if(localStorage.getItem("prevStep") === "email"){
                            const prevStepProofs = JSON.parse(localStorage.getItem("prevStepProofs"))
                            presenceContextObj = {
                                ...presenceContextObj,
                                ...prevStepProofs
                            }
                        } else if(localStorage.getItem("prevStep") === "captcha"){
                            const prevStepProofs = JSON.parse(localStorage.getItem("prevStepProofs"))
                            presenceContextObj = {
                                ...presenceContextObj,
                                ...prevStepProofs
                            }
                        }
                    }
                    const newStep = {
                        sessionId: localStorage.getItem("sessionId"),
                        currentStepId: localStorage.getItem("nextStep"),
                        presenceContext: presenceContextObj
                    }
                    console.log(newStep, "newStep")
                    const nextStep = await this.fetchNext(newStep);
                    // se non c'è nessuno step sucessivo comunica all'utente che è autenticato
                    if(nextStep.nextStep === null) {
                        this.loading = false
                        this.status = this.$t('verificato')
                        this.emailShow = false
                        this.captchaShow = false
                        this.showBtn = false
                        localStorage.removeItem("sessionId")
                        localStorage.removeItem("nextStep")
                        localStorage.removeItem("prevStep")
                        localStorage.removeItem("prevStepProofs")
                    // altrimenti esegue il prossimo
                    } else {
                        this.nextStep = nextStep.nextStep.id
                        this.time = new Date();
                        this.doStep(nextStep.nextStep.name, nextStep.nextStep.id)
                    }
                }
            }else{
                window.location.href="/forbidden";
            }
        },
        methods: {
            async fetchFirst(urlToken) {
                let apiUrl = `${this.$apiBaseUrl}/presence/step/first`;
                const tokenObject = {
                    token:urlToken
                }
                if(this.$keycloak.token) {
                    const bearerToken = this.$keycloak.token;
            
                    const config = {
                        headers: {
                            'Authorization': `Bearer ${bearerToken}`
                        },
                    };

                    try {
                        const response = await axios.post(apiUrl, tokenObject, config);
                        console.log(response.data)
                        return response.data;
                    } catch (error) {
                        console.error(error)
                        window.location.href="/forbidden";
                    }
                } else {
                    try {
                        const response = await axios.post(apiUrl, tokenObject);
                        console.log(response.data)
                        return response.data;
                    } catch (error) {
                        console.error(error)
                        window.location.href="/forbidden";
                    }
                }
            },
            async fetchNext(nextStep) {
                let apiUrl = `${this.$apiBaseUrl}/presence/step/next`;
                if(this.$keycloak.token) {
                    const bearerToken = this.$keycloak.token;
            
                    const config = {
                        headers: {
                            'Authorization': `Bearer ${bearerToken}`
                        },
                    };
                    try {
                        const response = await axios.post(apiUrl, nextStep, config);
                        console.log(response.data)
                        return response.data
                    } catch (error) {
                        console.error(error)
                    }
                } else {
                    try {
                        const response = await axios.post(apiUrl, nextStep);
                        console.log(response.data)
                        return response.data
                    } catch (error) {
                        console.error(error)
                    }
                }

            },
            doStep(stepName, stepId) {
                console.log("stepName: ", stepName);
                if (stepName === 'login') {
                    return this.handleLogin();
                } else if (stepName === 'email') {
                    return this.handleEmail()
                } else if (stepName === 'captcha') {
                    return this.handleCaptcha()
                }
            },
            handleLogin() {
                this.captchaShow = false
                this.emailShow = false
                if (this.$keycloak) {
                    localStorage.setItem("nextStep", this.nextStep)
                    this.$keycloak.login();
                } else {
                    console.error('Keycloak not initialized yet.');
                }
            },
            handleEmail() {
                this.loading = false
                this.emailShow = true
                this.captchaShow = false
            },
            handleCaptcha() {
                this.generate();
                this.loading = false
                this.emailShow = false
                this.captchaShow = true
                this.status2 = "Completa il captcha"
            },
            generate() {
                
                // Clear old input
                this.userCaptcha.filter = "";

                // Access the element to store
                // the generated captcha
                let uniquechar = "";

                const randomchar = "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";

                // Generate captcha for length of
                // 5 with random character
                for (let i = 1; i < 5; i++) {
                    uniquechar += randomchar.charAt(
                        Math.random() * randomchar.length)
                }

                // Store generated input
                this.captchaGenerated = uniquechar;
            },
            printmsg() {
            
                // Check whether the input is equal
                // to generated captcha or not
                console.log(this.userCaptcha.filter, "this.userCaptcha.filter");
                console.log(this.captchaGenerated, "this.captchaGenerated");
                if (this.userCaptcha.filter == this.captchaGenerated) {
                    this.captchaStatus = "Matched";
                    localStorage.setItem("nextStep", this.nextStep)
                    localStorage.setItem("prevStep", "captcha")
                    let prevStepProofs = {
                        errors: this.captchaErrors,
                        startTime: this.time,
                        finishTime: new Date()
                    }
                    localStorage.setItem("prevStepProofs", JSON.stringify(prevStepProofs))
                    setInterval(() => {
                        this.reloadPage();
                    }, 300);
                }
                else {
                    this.captchaStatus = "not Matched";
                    this.captchaErrors += 1;

                }
            },
            emailVerify() {
                if ((this.userEmail.filter).toLowerCase() == this.store.state.global.userData.email) {
                    this.emailStatus = "Matched";
                    localStorage.setItem("nextStep", this.nextStep)
                    localStorage.setItem("prevStep", "email")
                    let prevStepProofs = {
                        errors: this.emailErrors,
                        startTime: this.time,
                        finishTime: new Date()
                    }
                    localStorage.setItem("prevStepProofs", JSON.stringify(prevStepProofs))
                    setInterval(() => {
                        this.reloadPage();
                    }, 300);
                }
                else {
                    this.emailStatus = "not Matched";
                    this.emailErrors += 1;
                }
            },
            reloadPage() {
                window.location.reload()
            },
        }
    }
</script>

<style scoped>
    .loading-svg{
        width: 150px;
        height: 150px;
    }
    .main-container {
        margin-top: 50px;
        margin-bottom: 50px;
        min-height: 500px;
    }
    .loading-container {
        width: 100%;
        height: fit-content;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 20px;
    }
    #image{
        margin-top: 10px;
        width: fit-content;
        font-weight: 400;
        padding: 20px;
        height: 40px;
        user-select: none;
        text-decoration: line-through;
        font-style: italic;
        font-size: x-large;
        border: red 2px solid;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    #user-input{
        width: auto;
        padding: 10px;
        height: 40px;
        border: red 0px solid;
        display: flex;
        justify-content: center;
        align-items: center;
    }
    input{
        border:1px black solid;
    }
    .inline{
        display: flex;
        width: fit-content;
        height: fit-content;
        outline: black 1px solid;
        justify-content: center;
        align-items: center;
        padding: 10px;
    }
    #btn{
        box-shadow: 5px 5px 5px grey;
        color: aqua;
        margin: 10px;
        background-color: brown;
        width: 100px;
    }

    .captcha-container {
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        row-gap: 10px;
    }


    .input-container {
        display: flex;
        flex-direction: column;
        row-gap: 10px;
        justify-content: center;
    }

    .email-verification-container {
        row-gap: 10px;
        display: flex;
        width: fit-content;
        flex-direction: column;
        justify-content: center;
    }

    #user-email, #email-submit {
        padding: 10px;
    }
</style>
<script>
    import CoursesTable from '../components/CoursesTable.vue';
    import AddCourseForm from '../components/AddCourseForm.vue';
    import EditCourseForm from '../components/EditCourseForm.vue';
    const axios = require('axios');
    import { useStore } from 'vuex';
    export default {
        name: "CoursesView",
        components: {
            CoursesTable,
            AddCourseForm,
            EditCourseForm,
        },
        data() {
            return {
                loading: true,
                courses: [],
                store: useStore(),
                checkSteps: [],
                schools: [],
                editingCourseFinishDate: null,
                selectedSchoolName: null
            }
        },
        async created() {
            const me = await this.fetchMe();
            console.log(me);
            this.store.state.global.idSelectedSchool = null;
            this.store.state.global.selectedSchool = null;
            if(me.adminInfo){
                console.log("è admin");
                this.schools = me.adminInfo.schools
                console.log(this.schools, "this.schools");
                if(this.store.state.global.idSelectedSchool) {
                    this.checkSteps = await this.fetchCheckSteps();
                    this.updateCourses()
                }
                
            }
            
        },
        methods: {
            // fetch dei dati nel file db.json dell'array courses
            async fetchCourses() {
                const me = await this.fetchMe();
                const id = me.userId
                const apiUrl = `${this.$apiBaseUrl}/school/${this.store.state.global.idSelectedSchool}/modules`;
                const bearerToken = this.$keycloak.token;

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    const response = await axios.get(apiUrl, config);
                    console.log('Response:', response.data);
                    const reversed = response.data.reverse();
                    return reversed;
                } catch (error) {
                    console.error('Error:', error.message);
                }
            },
            async updateCourses() {
                this.courses = await this.fetchCourses();
                setInterval(() => {
                    this.loading = false
                }, 300);
            },
            async fetchMe() {
                console.log("entered fetchMe");
                const apiUrl = `${this.$apiBaseUrl}/users/me`;
                const bearerToken = this.$keycloak.token;

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    const response = await axios.get(apiUrl, config);
                    console.log('Response:', response.data);
                    return response.data;
                } catch (error) {
                    console.error('Error:', error.message);
                }
            },
            async fetchCheckSteps() {
                const apiUrl = `${this.$apiBaseUrl}/checkSteps`;
                const bearerToken = this.$keycloak.token;

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    const response = await axios.get(apiUrl, config);
                    console.log('Response:', response.data);
                    return response.data;
                } catch (error) {
                    console.error('Error:', error.message);
                }
            },
            async fetchCourse(id) {
                const apiUrl = `${this.$apiBaseUrl}/module/${id}`;
                const bearerToken = this.$keycloak.token;

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    const response = await axios.get(apiUrl, config);
                    console.log('Response:', response.data);
                    return response.data;
                } catch (error) {
                    console.error('Error:', error.message);
                }
            },
            async fetchaAssignmentsCourse(idcourse) {
              const res = await fetch(`http://localhost:5000/assignments?idcourse=${idcourse}`)

              const data = await res.json()

              return data.length > 0;
            },
            async deleteCourse(id) {
                if(confirm(this.$t('conferma_eliminazione_corso'))){
                    console.log(id, "id");
                    const apiUrl = `${this.$apiBaseUrl}/module/${id}`;
                    const bearerToken = this.$keycloak.token;

                    const config = {
                        headers: {
                            'Authorization': `Bearer ${bearerToken}`,
                        },
                    };

                    try {
                        // Effettua la richiesta DELETE
                        const response = await axios.delete(apiUrl, config);
                        console.log('Course deleted:', response.data);

                        this.courses = await this.fetchCourses();
                        // modificare testi alert !!!!!!!!!!
                        if(this.store.state.courses.showEditCourseForm) {
                            this.store.dispatch('toggleEditCourseForm')
                        }
                        this.store.dispatch('showAlert' , [this.$t('successo'), `${this.$t('successo_eliminazione_corso')}`, 5000, "#04c717", "ep:success-filled"])
                    } catch (error) {
                        if(error.response.status === 404){
                            this.store.dispatch('showAlert' , [this.$t('errore'), this.$t('errore_docenze_corso'), 5000, "#c42116", "bxs:error"])
                        } else {
                            this.store.dispatch('showAlert' , [this.$t('errore'), `${this.$t('errore_eliminazione_corso')}  ${error.message}`, 5000, "#c42116", "bxs:error"])
                        }
                    }
                }
            },
            async createCourse(course) {
                // Verifica se esiste già un insegnante con la stessa userEmail
                const courseExists = this.courses.some(existingCourse => existingCourse.nome === course.nome);
                
                if (courseExists) {
                    this.store.dispatch('showAlert' , [this.$t('errore'), this.$t('errore_corso_esistente'), 5000, "#c42116", "bxs:error"])
                    return; // Non creare un nuovo insegnante se ne esiste già uno con la stessa userEmail
                }

                const apiUrl = `${this.$apiBaseUrl}/module/${this.store.state.global.idSelectedSchool}`;
                const bearerToken = this.$keycloak.token;

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    console.log(course);
                    const response = await axios.post(apiUrl, course, config);
                    console.log('Course created:', response.data);
                    this.courses = await this.fetchCourses();
                    this.store.dispatch('toggleAddCourseForm')
                    this.store.dispatch('showAlert' , [this.$t('successo'), `${this.$t('successo_creazione_corso')}`, 5000, "#04c717", "ep:success-filled"])
                } catch (error) {
                    this.store.dispatch('showAlert' , [this.$t('errore'), `${this.$t('errore_creazione_corso')}  ${error.message}`, 5000, "#c42116", "bxs:error"])
                }
            },
            async editCourse(newCourse) {
                console.log("in editCourse");
                const [updatedCourse, id] = newCourse;
                console.log(updatedCourse, "updatedCourse");

                const courseExists = this.courses.some(existingCourse => existingCourse.nome === updatedCourse.nome && existingCourse.id !== id);
                
                if (courseExists) {
                    this.store.dispatch('showAlert' , [this.$t('errore'), this.$t('errore_esistenza_corso'), 5000, "#c42116", "bxs:error"])
                    return;
                }

                const apiUrl = `${this.$apiBaseUrl}/module/${id}`;
                const bearerToken = this.$keycloak.token;

                const config = {
                    headers: {
                        'Authorization': `Bearer ${bearerToken}`,
                    },
                };

                try {
                    const response = await axios.put(apiUrl, updatedCourse, config);
                    console.log('Course updated:', response.data);

                    this.courses = await this.fetchCourses();
                    this.store.dispatch('toggleEditCourseForm')
                    this.store.dispatch('showAlert' , [this.$t('successo'), `${this.$t('successo_modifica_corso')}`, 5000, "#04c717", "ep:success-filled"])
                } catch (error) {
                    this.store.dispatch('showAlert' , [this.$t('errore'), `${this.$t('errore_modifica_corso')}  ${error.message}`, 5000, "#c42116", "bxs:error"])
                }
            }
        },
    }
</script>

<template>
    <div style="margin-top: 47px; margin-bottom: 50px; min-height: 400px;">
        <div v-show="this.store.state.courses.ariaShowCourse">
            <CoursesTable :schools="schools" :loading="loading" :courses="courses" @update-school="updateCourses" @toggle-add-course-form="this.store.dispatch('toggleAddCourseForm')" @delete-course="deleteCourse"/>
        </div>    
    </div>
    <div v-show="this.store.state.courses.showAddCourseForm" class="form-container">
        <AddCourseForm :checkSteps="checkSteps" @create-course="createCourse"/>
    </div>
    <div v-show="this.store.state.courses.showEditCourseForm" class="form-container">
        <EditCourseForm @edit-course="editCourse" :checkSteps="checkSteps" :editingCourse="this.store.state.courses.editingCourse" :editingCourseFinishDate="this.store.state.courses.editCourseFinishDate" @delete-course="deleteCourse"/>
    </div>
</template>

<style scoped>
    .form-container{
        width: 100%;
        height: 100vh;
        display: flex;
        flex-direction: column;
        justify-content: center;
        row-gap: 30px;
        align-items: center;
        position: absolute;
        top: 0;
        background-color: rgba(0,0,0,0.6);
        z-index: 100;
        backdrop-filter: blur(2px);
    }
</style>
<script setup>
  import { useField, useForm } from 'vee-validate'
  import { useStore } from 'vuex/dist/vuex.esm-bundler.js';
  import Button from './Button.vue';
  import { Icon } from '@iconify/vue';
    
  const emit = defineEmits(['create-course'])
  const store2 = useStore()
  const { handleSubmit } = useForm({
    validationSchema: {
        coursename(value) {
            if (value?.length >= 2) return true
            return 'Course Name needs to be at least 2 characters.'
        },
    },
  })

  const coursename = useField('coursename')

  const submit = handleSubmit(values => {
    const checkboxes = document.querySelectorAll('input[type="checkbox"]');

    // Filtra solo quelli selezionati e ottieni i loro valori
    const selectedCheckboxes = Array.from(checkboxes)
      .filter(checkbox => checkbox.checked)
      .map(checkbox => checkbox.value);
    let date = new Date();
    console.log(date);
    const course = {
      nome: values.coursename,
      stepsRequired: selectedCheckboxes,
      dataFine: store2.state.courses.addCourseFinishDate,
      dataInizio: date,
      schoolId: store2.state.global.idSelectedSchool,
    }
    console.log(course);
    emit("create-course", course)
  })
</script>

<script>
import { useStore } from 'vuex/dist/vuex.esm-bundler.js';
  import { useI18n } from 'vue-i18n'
  export default {
    name: "AddCourseForm",
    components: {
        Button,
        Icon,
    },
    setup() {
      const { t } = useI18n() // use as global scope
      return {
          t
      }
    },
    props: {
      checkSteps: {
        type: Array,
      }
    },
    data() {
      return {
        store: useStore(),
        selected: [],
      }
    },
    watch: {
      selected: {
        handler(newValue, oldValue) {
          // Handle deselection of items with stepsRequired
          oldValue.forEach(oldItemId => {
            const oldItem = this.checkSteps.find(item => item.id === oldItemId);
            if (oldItem && !newValue.includes(oldItemId)) {
              this.deselectDependentItems(oldItem);
            }
          });
        },
        deep: true,
      },
    },
    methods: {
      isSelectable(item) {
        // Check if all required steps are selected
        return item.required.every(step =>
          this.selected.includes(step.id)
        );
      },
      deselectDependentItems(item) {
        // Deselect items that have this item in their stepsRequired
        this.checkSteps.forEach(checkItem => {
          if (checkItem.required.some(step => step.id === item.id)) {
            this.selected = this.selected.filter(
              selectedItemId => selectedItemId !== checkItem.id
            );
          }
        });
      },
    },
  }
</script>

<template>
    <form @submit.prevent="submit" role="form" :aria-label="$t('form_aggiunta_corsi')">
      <label for="coursename" class="sr-only">{{ $t('nome_corso') }}</label>
      <v-text-field
        variant="outlined"
        v-model="coursename.value.value"
        :error-messages="coursename.errorMessage.value"
        :label="$t('nome_corso')"
        :aria-label="$t('nome_corso')"
        role="textbox"
        aria-required="true"
        :aria-invalid="coursename.errorMessage.value ? 'true' : 'false'"
      ></v-text-field>
      <div>
        <v-dialog width="500">
          <template v-slot:activator="{ props }">
            <v-btn variant="outlined" v-bind="props" text="Seleziona data termine corso"> </v-btn>
          </template>
        
          <template v-slot:default="{ isActive }">
            <v-card title="SELEZIONA DATA" style="display: flex; justify-content: center; align-items: center;">
              <v-date-picker
                  v-model="this.store.state.courses.addCourseFinishDate"
                  style="border:1px solid black"
              ></v-date-picker>
              <v-card-actions>
                <v-spacer></v-spacer>
        
                <v-btn variant="outlined"
                  text="INVIA"
                  color="#04c717"
                  @click="isActive.value = false"
                ></v-btn>
                <v-btn variant="outlined"
                  text="CANCELLA DATA"
                  color="#fe2315"
                  @click="this.store.state.courses.addCourseFinishDate = null"
                ></v-btn>

              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </div>

      <div class="checkBox-container-title">
        <p>{{ $t('step_selection_title') }}</p>
        <v-dialog width="760">
          <template v-slot:activator="{ props }">
            <v-btn :icon="true" variant="plain" :ripple="false" v-bind="props" text="Open Dialog">
              <Icon icon="material-symbols-light:chat-info" width="30px" height="30px"/>
            </v-btn>
          </template>
        
          <template v-slot:default="{ isActive }">
            <v-card :title="$t('step_info_title')">
              <v-card-text width="1000">
                {{ $t('step_info_1') }}
                <br>
                <br>
                {{ $t('step_info_2') }}
                <br>
                <br>
                <ul style="margin-left: 30px">
                  <li>{{ $t('step_info_3') }}</li>
                  <li>{{ $t('step_info_4') }}</li>
                  <li>{{ $t('step_info_5') }}</li>
                  <li>{{ $t('step_info_6') }}</li>
                  <li>{{ $t('step_info_7') }}</li>
                </ul>
                <br>
                {{ $t('step_info_8') }}
                <br>
                <br>
                <ul style="margin-left: 30px">
                  <li>{{ $t('step_info_9') }}</li>
                  <li>{{ $t('step_info_10') }}</li>
                </ul>
                <br>
                {{ $t('step_info_11') }} 
                <br>
                <br>
                {{ $t('step_info_12') }}
              </v-card-text>
        
              <v-card-actions>
                <v-spacer></v-spacer>
        
                <v-btn
                  :text="$t('step_info_close')"
                  @click="isActive.value = false"
                  variant="outlined"
                ></v-btn>
              </v-card-actions>
            </v-card>
          </template>
        </v-dialog>
      </div>
      <div class="checkBoxes-container">
        <div v-for="item in checkSteps" class="checkBox-container">
          <div>
            <input 
              type="checkbox" 
              v-model="selected"
              :value="item.id"
              :disabled="!isSelectable(item)"
            >
            <v-tooltip activator="parent" location="bottom">
              <span v-if="item.name === 'login'">{{ $t('login_step') }}</span>
              <span v-if="item.name === 'captcha'">{{ $t('captcha_step') }}</span>
              <span v-if="item.name === 'email'">{{ $t('email_step') }}</span>
            </v-tooltip>
          </div>
          <label for="checkbox">{{ (item.name).toUpperCase() }}</label>
        </div>
      </div>
      <div class="big-btn-group" role="group" :aria-label="$t('azioni_form')">
        <div class="btn-group">
          <v-btn color="#04c717" class="me-4" type="submit" role="button" :aria-label="$t('invio')">{{ $t('invio') }}</v-btn>
          <Button buttoncolor="black" :buttontext="$t('chiudi')" @btn-click="store.dispatch('toggleAddCourseForm')" role="button" :aria-label="$t('chiudi')" />
        </div>
      </div>
    </form>
  </template>

<style scoped>
  form{
      width: 400px;
      background-color: white;
      display: flex;
      flex-direction: column;
      row-gap: 10px;
      padding: 20px;
      border-radius: 20px;
  }

  .sr-only {
    position: absolute;
    width: 1px;
    height: 1px;
    margin: -1px;
    padding: 0;
    overflow: hidden;
    clip: rect(0, 0, 0, 0);
    border: 0;
  }

  .btn-group{
    width: fit-content;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
  }
  .checkBoxes-container {
    height: 130px;
    width: fit-content;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    row-gap: 10px;
    padding: 10px;
    border: 1px solid black;
    overflow: auto;
  }
  
  .checkBox-container {
    height: 30px;
    display: flex;
    align-items: center;
    column-gap: 15px;
  }
  .checkBox-container input{
    transform: scale(1.5);
    background-color: #ffffff;
  }

  .checkBox-container-title {
    display: flex; 
    align-items: center; 
  }

  .checkBox-container-title svg:hover {
    cursor: pointer;
  }

  .big-btn-group{
    width: 100%;
    height: auto;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 20px
  }
</style>
<template>
    <div class="p-table-container">
        <div class="p-top-container"> 
            <Button
                buttoncolor="black"
                :buttontext="$t('agg_scuola')"
                @btn-click="this.store.dispatch('toggleCreateSchool')"
                :aria-label="$t('agg_scuola')"
                role="button"
            />
            <input
                class="search"
                @input="searchChange"
                type="search"
                name="searchschool"
                id="searchschool"
                :placeholder="$t('cerca_scuola')"
                v-model.trim="searchschool.filter"
                :aria-label="$t('cerca_scuola')"
                role="search"
                style="height: 50px;"
            />
        </div>
        <div>
            <v-skeleton-loader 
                boilerplate
                :loading="this.loading"
                type="table-thead, table-tbody, table-tfoot"
                width="500"
            >
                <v-data-table :items="this.filteredSchools" :headers="headers"  style="outline: 1px solid rgba(0,0,0,0.2); border-radius: 10px; max-height: 500px;">
                    <template v-slot:item.id="{ item }">
                        <div class="btn-group">
                            <Button role="button" :aria-label="$t('modifica_scuola')" buttoncolor="grey" @btn-click="this.store.dispatch('toggleEditSchool', [item])">
                                <template #icon>
                                    <Icon color="black" icon="tabler:edit" width="30" height="30" />
                                </template>
                                <template #tooltip>
                                    <v-tooltip activator="parent" location="bottom">
                                        {{ $t('modifica') }}
                                    </v-tooltip>
                                </template>
                            </Button>
                        </div>
                    </template>
                </v-data-table>
            </v-skeleton-loader>
        </div>
    </div>
</template>

<script>
    import { Icon } from '@iconify/vue';
    import Button from './Button.vue';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n'
    export default {
        name: "SchoolsTable",
        components: {
            Icon,
            Button,
        },
        setup() {
            const { t } = useI18n() // use as global scope
            return {
                t
            }
        },
        data() {
            return {
                searchschool: {
                    filter: ""
                },
                filteredSchools: [],
                store: useStore(),
                headers: [
                    { title: this.$t('nome_scuola'), value: 'name', sortable: true },
                    { title: '', value: 'id', sortable: false },
                ],
            }
        },
        props: {
            schools: {
                type: Array,
            },
            loading: {
                type: Boolean,
            }
        },
        mounted() {
            this.filteredSchools = [...this.schools];
            this.searchChange();
        },
        watch: {
            schools: function(newVal, oldVal) {
                this.searchChange()
            }
        },
        methods: {
            searchChange() {
                const searchTerm = this.searchschool.filter.trim().toLowerCase();
                if (searchTerm) {
                    this.filteredSchools = this.schools.filter((school) => {
                        return (
                            school.name.toLowerCase().includes(searchTerm)
                        );
                    });
                } else {
                    this.filteredSchools = [...this.schools];
                }
            },
        }
    }
</script>

<style scoped>
    .p-table-container{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
    }

    .search {
        width: 200px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 10px;
        padding-left: 10px;
        outline: solid 1px rgba(0,0,0,0.3);
        border-radius: 5px
    }
    .btn-group{
        margin-left:240px;
        width: fit-content;
        /* outline: black 1px solid; */
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 10px;
    }

    .p-top-container{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 20px;
    }
</style>
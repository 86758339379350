import { createRouter, createWebHistory } from "vue-router";
import TeachersView from "../views/TeachersView.vue";
import CoursesView from "../views/CoursesView.vue";
import AssignmentsView from "../views/AssignmentsView.vue";
import SchoolsView from "../views/SchoolsView.vue";
import LecturePresencesView from "../views/LecturePresencesView.vue";
import PresencesView from "../views/PresencesView.vue";
import TeacherAssignmentsView from "../views/TeacherAssignmentsView.vue";
import StudentVerificationView from "../views/StudentVerificationView.vue";
import HomeView from "../views/HomeView.vue";
import AdminView from "../views/AdminView.vue";
import ForbiddenView from "../views/ForbiddenView.vue";
import store from "../store";

const routes = [
  {
    path: "/",
    name: "home",
    component: HomeView,
    beforeEnter: (to, from, next) => {
      store.state.global.student = false;
      store.state.global.showScreenAlertState = false;
      next();
    },
  },
  {
    path: "/admin",
    name: "admin",
    component: AdminView,
    beforeEnter: (to, from, next) => {
      if (store.state.global.userData.roles.includes("ROLE_ADMIN")) {
        store.state.global.student = false;
        store.state.global.showScreenAlertState = true;
        next();
      } else {
        next("/forbidden");
      }
    },
  },
  {
    path: "/teachers",
    name: "teachers",
    component: TeachersView,
    beforeEnter: (to, from, next) => {
      if (store.state.global.userData.roles.includes("ROLE_ADMIN")) {
        store.state.global.student = false;
        store.state.global.showScreenAlertState = true;
        next();
      } else {
        next("/forbidden");
      }
    },
  },
  {
    path: "/courses",
    name: "courses",
    component: CoursesView,
    beforeEnter: (to, from, next) => {
      if (store.state.global.userData.roles.includes("ROLE_ADMIN")) {
        store.state.global.student = false;
        store.state.global.showScreenAlertState = true;
        next();
      } else {
        next("/forbidden");
      }
    },
  },
  {
    path: "/assignments",
    name: "assignments",
    component: AssignmentsView,
    beforeEnter: (to, from, next) => {
      if (
        store.state.global.userData.roles.includes("ROLE_ADMIN") ||
        store.state.global.userData.roles.includes("ROLE_PROFESSOR")
      ) {
        store.state.global.student = false;
        store.state.global.showScreenAlertState = true;
        next();
      } else {
        next("/forbidden");
      }
    },
  },
  {
    path: "/schools",
    name: "schools",
    component: SchoolsView,
    beforeEnter: (to, from, next) => {
      if (store.state.global.userData.roles.includes("ROLE_SUPER_ADMIN")) {
        store.state.global.student = false;
        store.state.global.showScreenAlertState = true;
        next();
      } else {
        next("/forbidden");
      }
    },
  },
  {
    path: "/teacher-assignments",
    name: "teacher-assignments",
    component: TeacherAssignmentsView,
    beforeEnter: (to, from, next) => {
      if (store.state.global.userData.roles.includes("ROLE_PROFESSOR")) {
        store.state.global.student = false;
        store.state.global.showScreenAlertState = true;
        next();
      } else {
        next("/forbidden");
      }
    },
  },
  {
    path: "/lecture-presences",
    name: "lecture-presences",
    component: LecturePresencesView,
    beforeEnter: (to, from, next) => {
      if (store.state.global.userData.roles.includes("ROLE_PROFESSOR")) {
        store.state.global.student = false;
        store.state.global.showScreenAlertState = true;
        next();
      } else {
        next("/forbidden");
      }
    },
  },
  {
    path: "/presences",
    name: "presences",
    component: PresencesView,
    beforeEnter: (to, from, next) => {
      if (store.state.global.userData.roles.includes("ROLE_ADMIN")) {
        store.state.global.student = false;
        store.state.global.showScreenAlertState = true;
        next();
      } else {
        next("/forbidden");
      }
    },
  },
  {
    path: "/student-verification",
    name: "student-verification",
    component: StudentVerificationView,
    beforeEnter: (to, from, next) => {
      store.state.global.student = true;
      store.state.global.showScreenAlertState = false;
      next();
    },
  },
  {
    path: "/forbidden",
    name: "forbidden",
    component: ForbiddenView,
    beforeEnter: (to, from, next) => {
      store.state.global.student = false;
      store.state.global.showScreenAlertState = false;
      next();
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
});

export default router;

<script>
    import { Icon } from '@iconify/vue';
    import Button from './Button.vue';
    import { useStore } from 'vuex';
    import { useI18n } from 'vue-i18n'
    export default {
        name: "TeachersTable",
        components: {
            Icon,
            Button,
        },
        setup() {
            const { t } = useI18n() // use as global scope
            return {
                t
            }
        },
        data() {
            return {
                filteredTeachers: [],
                searchteacher: {
                    filter: ""
                },
                search: '',
                store: useStore(),
                headers: [
                    { title: this.$t('nome_professore'), value: 'name', sortable: true },
                    { title: this.$t('cognome_professore'), value: 'surname', sortable: true },
                    { title: this.$t('email_professore'), value: 'userEmail', sortable: true },
                    { title: '', value: 'userId', sortable: false },
                ],
                selectedSchool: null,
            }
        },
        props: {
            teachers: {
                type: Array,
            },
            schools: {
                type: Array,
            },
            loading: {
              type: Boolean
            }
        },
        mounted() {
            this.filteredTeachers = [...this.teachers];
            this.searchChange();
            this.selectedSchool = null;
        },
        // reazione ai cambiamenti dei props
        watch: {
            teachers: function(newVal, oldVal) {
                this.searchChange()
            },
            selectedSchool: function(newValue) {
                this.updateSelectedSchool(newValue);
            },
        },
        methods: {
            searchChange() {
                const searchTerm = this.searchteacher.filter.trim().toLowerCase();
                if (searchTerm) {
                    this.filteredTeachers = this.teachers.filter((teacher) => {
                        const fullName = `${teacher.name} ${teacher.surname}`.toLowerCase();
                        return (
                            fullName.includes(searchTerm) ||
                            teacher.userEmail.toLowerCase().includes(searchTerm)
                        );
                    });
                } else {
                    this.filteredTeachers = [...this.teachers];
                }
            },
            formatDate(dateTime) {
                return moment(dateTime).format('D/M/YYYY');
            },
            updateSelectedSchool(newValue) {
              console.log("scuola cambiata");
              const selectedSchoolObj = this.schools.find(school => school.name === newValue);

              if (selectedSchoolObj) {
                // Update the Vuex store with selectedSchool's name and ID
                this.store.state.global.idSelectedSchool = selectedSchoolObj.id
                this.store.state.global.selectedSchool = newValue
                this.$emit('update-school', selectedSchoolObj.id);
                console.log(this.store.state.global.idSelectedSchool);
              }
            },
        }
    }
</script>

<template>
    <div class="p-table-container">
        <div class="p-top-container">
            <Button :aria-label="$t('agg_professore')" buttoncolor="black" :buttontext="$t('agg_professore')"  @btn-click="this.store.dispatch('toggleAddTeacherForm')" v-if="this.store.state.global.selectedSchool" />
            <input
                class="search"
                @input="searchChange"
                type="search"
                name="searchteacher"
                id="searchteacher"
                :placeholder="$t('cerca_professore')"
                v-model.trim="searchteacher.filter"
                :aria-label="$t('cerca_professore')"
                role="search"
                style="height: 50px;"
                v-if="this.store.state.global.selectedSchool"
            />
            <v-select
                :label="$t('seleziona_scuola')"
                :items="this.schools.map(school => school.name)"
                style="width: 200px; height: 55px;"
                variant="outlined"
                v-model="selectedSchool"
                @change="updateSelectedSchool"
                @input="updateSelectedSchool"
            ></v-select>
        </div>
        <div v-if="this.store.state.global.selectedSchool">
            <v-skeleton-loader 
                boilerplate
                :loading="this.loading"
                type="table-thead, table-tbody, table-tfoot"
                width="853"
            >
                <v-data-table :items="this.filteredTeachers" :headers="headers" style="outline: 1px solid rgba(0,0,0,0.2); border-radius: 10px; max-height: 420px; width: fit-content; min-width: 854px;">
                    <template v-slot:item.userId="{ item }">
                        <div class="btn-group">
                            <Button role="button" :aria-label="$t('elimina_professore')" buttoncolor="#fe2315" @btn-click="$emit('delete-teacher', item.userId)">
                                <template #icon>
                                    <Icon color="black" icon="typcn:delete-outline" width="30" height="30" />
                                </template>
                                <template #tooltip>
                                    <v-tooltip activator="parent" location="bottom">
                                        {{ $t('elimina') }}
                                    </v-tooltip>
                                </template>
                            </Button>
                            <Button role="button" :aria-label="$t('modifica_professore')" buttoncolor="grey" @btn-click="this.store.dispatch('toggleEditTeacherForm', [item])">
                                <template #icon>
                                    <Icon color="black" icon="tabler:edit" width="30" height="30" />
                                </template>
                                <template #tooltip>
                                    <v-tooltip activator="parent" location="bottom">
                                        {{ $t('modifica') }}
                                    </v-tooltip>
                                </template>
                            </Button>
                            <Button :buttontext="$t('vedi_docenze')" role="button" buttoncolor="grey" :to="'/assignments?teacherId='+item.userId+'&teacherName='+item.name+' '+item.surname"/>
                        </div>
                    </template>
                </v-data-table>
            </v-skeleton-loader>
        </div>
        <div v-else>{{ $t('seleziona_scuola') }}</div>
    </div>
</template>

<style scoped>
    .p-table-container{
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        align-items: center;
        row-gap: 20px;
    }

    .search {
        width: 200px;
        height: 100%;
        display: flex;
        justify-content: center;
        align-items: center;
        padding-right: 10px;
        padding-left: 10px;
        outline: solid 1px rgba(0,0,0,0.3);
        border-radius: 5px
    }
    .btn-group{
        margin-left:20px;
        width: fit-content;
        /* outline: black 1px solid; */
        display: flex;
        justify-content: space-between;
        align-items: center;
        column-gap: 10px;
    }

    .p-top-container{
        width: 100%;
        height: auto;
        display: flex;
        justify-content: center;
        align-items: center;
        column-gap: 20px;
    }
</style>
<template>
    <div style="margin-top: 47px; margin-bottom: 50px; min-height: 400px; width: 100%; display: flex; justify-content:center; align-items: center;">
        <PresencesTable @update-school="updateCourses" @update-course="updatePresences" :presences="presences" :courses="courses" :schools="schools" :loading="loading"/>

    </div>
</template>

<script>
    const axios = require('axios');
    import { useStore } from 'vuex';
  import PresencesTable from '../components/PresencesTable.vue'
  export default {
    data() {
      return {
        presences: [],
        courses: [],
        schools: [],
        store: useStore(),
        loading: true,
      };
    },
    components: {
        PresencesTable
    },
    methods: {
        async fetchPresences() {
            const apiUrl = `${this.$apiBaseUrl}/presence/${this.store.state.global.idSelectedCourse}`;
            const bearerToken = this.$keycloak.token;

            const config = {
                headers: {
                    'Authorization': `Bearer ${bearerToken}`,
                },
            };

            try {
                const response = await axios.get(apiUrl, config);
                console.log('Response:', response.data);
                const reversed = response.data.reverse();
                return reversed;
            } catch (error) {
                console.error('Error:', error.message);
            }
        },
        async fetchCourses() {
            const apiUrl = `${this.$apiBaseUrl}/school/${this.store.state.global.idSelectedSchool}/modules`;
            const bearerToken = this.$keycloak.token;

            const config = {
                headers: {
                    'Authorization': `Bearer ${bearerToken}`,
                },
            };

            try {
                const response = await axios.get(apiUrl, config);
                console.log('Response:', response.data);
                const reversed = response.data.reverse();
                return reversed;
            } catch (error) {
                console.error('Error:', error.message);
            }
        },
        async fetchMe() {
            console.log("entered fetchMe");
            const apiUrl = `${this.$apiBaseUrl}/users/me`;
            const bearerToken = this.$keycloak.token;

            const config = {
                headers: {
                    'Authorization': `Bearer ${bearerToken}`,
                },
            };

            try {
                const response = await axios.get(apiUrl, config);
                console.log('Response:', response.data);
                return response.data;
            } catch (error) {
                console.error('Error:', error.message);
            }
        },
        async updatePresences() {
            try {
                this.presences = await this.fetchPresences();
                setInterval(() => {
                    this.loading = false
                }, 300);
            } catch (error) {
                // Handle the error if needed
                console.error('Error in created hook:', error.message);
            }
        },
        async updateCourses() {
            try {
                this.courses = await this.fetchCourses();
            } catch (error) {
                // Handle the error if needed
                console.error('Error in created hook:', error.message);
            }
        }
    },
    async created() {
        const me = await this.fetchMe();
        console.log(me);
        this.store.state.global.idSelectedSchool = null;
        this.store.state.global.selectedSchool = null;
        this.store.state.global.idSelectedCourse = null;
        this.store.state.global.selectedCourse = null;
        if(me.adminInfo){
            this.schools = me.adminInfo.schools
            if(this.store.state.global.idSelectedSchool) {
                if(this.store.state.global.idSelectedCourse) {
                    this.updatePresences()
                } else {
                    this.updateCourses()
                }
            }
        }
    },
  };
</script>

<style scoped>

</style>